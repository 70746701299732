import React from 'react';
import { Select } from 'antd'; // 加载 JS
import './selector.css';

const { Option } = Select;

export default function selector(props) {
  const { prices = [], brands = [], fabricSearch } = props;
  return (
    <div className="selector-container">
      <div className="selector-input-container">
      <img src={require('../images/search.svg')} alt="" className="selector-input-img" />
      <input className="selector-input" placeholder="输入面料号搜索" onChange={e => fabricSearch({ fabricSearch: e.nativeEvent.target.value })} />
      </div>
      <Select className="selector-select" style={{ fontSize: 12 }} allowClear placeholder="价格区间" onChange={(priceSeach) => fabricSearch({ priceSeach })}>
        {prices.map(({ price_max = '', price_min = '' }) =>  {
          const value = JSON.stringify({ price_max, price_min });
          return <Option style={{ fontSize: 12, paddingRight: 0 }} key={value} value={value}>{ price_max ? `${price_min}~${price_max}` : `${price_min}以上`}</Option>
        })}
      </Select>
      <Select className="selector-select" style={{ fontSize: 12 }} allowClear placeholder="面料品牌" onChange={(brandSearch) => fabricSearch({ brandSearch })}>
        {brands.map(({ fabric_brand: value }) => {
          return <Option style={{ fontSize: 12, paddingRight: 0 }} key={value} value={value}>{value}</Option>
        })}
      </Select>
    </div>
  );
}
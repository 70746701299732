const hbGongYiRules = {
  MXF: {
    "1003": { // 领形
      "1022": { // "平驳领"
        rules: {
          "1004": { // 驳领宽（6.7.8.9.10）
            enabled: ["1026", "1027", "1028", "1029", "1030"],
          }
        }
      },
      "1023": { // "戗驳领"
        rules: {
          "1004": { // 驳领宽（6.7.8.9.10）
            enabled: ["1026", "1027", "1028", "1029", "1030"],
          }
        }
      },
      "1024": { // 青果领
        rules: {
          disabled: {
            "1005": true,
            "1006": true,
          }, // 插花眼形 插花眼色
          "1004": { // 驳领宽（5 7 9）
            enabled: ["1025", "1027", "1029"],
          }
        }
      }
    }
  },
  MDY: {
    "1003": { // 领形
      "1024": { // 青果领
        rules: {
          disabled: {
            "1005": true,
            "1006": true,
          }, // 插花眼形 插花眼色
          "1004": { // 驳领宽（5 7 9）
            enabled: ["1025", "1027", "1029"],
          }
        }
      }
    }
  },
  MCY: {},
  MXK: {},
  MMJ: {},
  MJK: {},
};

const csGongYiRules = {
  MXF: {
    "1003": { // 领形
      "1024": { // 青果领
        rules: {
          disabled: {
            "1005": true,
            "1006": true,
          }, // 插花眼形 插花眼色
          "1004": { // 驳领宽（5 7 9）
            enabled: ["1025", "1027", "1029"],
          }
        }
      }
    }
  },
  MDY: {
    "1003": { // 领形
      "1024": { // 青果领
        rules: {
          disabled: {
            "1005": true,
            "1006": true,
          }, // 插花眼形 插花眼色
          "1004": { // 驳领宽（5 7 9）
            enabled: ["1025", "1027", "1029"],
          }
        }
      }
    }
  },
  MCY: {},
  MXK: {},
  MMJ: {},
  MJK: {},
  MXXK: {}
}

const gongYiRules = {
  hb: hbGongYiRules,
  cs: csGongYiRules,
}

export default gongYiRules;
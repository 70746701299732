import { BASEURL_CUSTOM } from '../Global.js'
import { GetPathParams } from '../util.js';

const { appid } = GetPathParams();

export async function request(url, { method, body }) {
  const response = await fetch(BASEURL_CUSTOM + url, { 
    method,
    headers:{
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      appid,
    },
    body,
    mode:'cors',
    cache:'default'
  })
  const result = await response.json();
  return result;
}

export async function requestform(url, { method, body }) {
  const response = await fetch(BASEURL_CUSTOM + url, { 
    method,
    headers:{
      Accept: 'application/json',
      appid,
    },
    body,
    mode:'cors',
    cache:'default'
  })
  const result = await response.json();
  return result;
}
export function toParamStr(param) {
  let result = '';
  for (const name in param) {
    if (typeof param[name] !== 'function') {
      result += `&${name}=${encodeURI(param[name])}`;
    }
  }
  return result ? ('?' + result.substring(1)) : '';
}

export async function get(url, payload) {
  return request(url + toParamStr(payload), { method: 'GET' });
}

export async function post(url, body) {
  return request(url, { method: 'POST', body: JSON.stringify(body) } )
}

export async function upLoad(url, body) {
  return requestform(url, { method: 'POST',  body} )
}
import { BASEURL } from '../Global.js';
import DefaultGongYi from './defaultGongYi';
import { get_select_crafts_work } from './common.js';

const DY_DEF = DefaultGongYi.MDY;

const dayi_map = {
                '前门扣':           "6002",
                '领型':             "6003",
                '驳领宽':           "6004",
                '插花眼形':         "6005",
                '插花眼色':         "6006",
                '上口袋':           "6007",
                '下口袋':           "6008",
                '卡袋':             "6009",
                '后衩':             "6010",
                '袖口形状':         "6011",
                '袖纽扣':           "6012",
                '袖扣眼方向':       "6013",
                '袖扣间距':         "6014",
                '单排三粒扣':       "6015",
                '单排四粒扣':       "6016",
                '单排暗门襟三粒扣': "6018",
                '单排暗门襟四粒扣': "6019",
                '双排四粒扣二':     "6020",
                '双排六粒扣二':     "6021",
                '青果领':           "6025",
                '驳领宽5CM':        "6026",
                '驳领宽7CM':        "6027",
                '驳领宽8CM':        "6028",
                '驳领宽9CM':        "6029",
                '顺色' :            "6037",
                '无手巾袋' :        "6048",
                '无卡袋':           "6054",
                '内折袖口' :        "6062",
                '尖角袖袢袖口':     "6063",

                // 以下是自定义的
                '扣眼':             "ky",
                '扣子' :            "kz",
                '领带':             "ld",
                '衬衫':             "cs",
                '袖袢':             "xp",
                '侧片' :            "dayi_cp",
                '后领':             "hl",
};
export const get_Dayi_Jsons = (craftWorks, indexArr) => {
  const appendix = [
    BASEURL + '/model/mdy/' + dayi_map.领带 + '.json',
    BASEURL + '/model/mdy/'+ dayi_map.衬衫 + '.json',
    BASEURL + '/model/mdy/'+ dayi_map.侧片 + '.json',
  ]
  const dingzhiData = get_select_crafts_work(craftWorks, DY_DEF);
  const lingxing_jsons = get_lingxing_jsons(dingzhiData);
  const shangkoudai_jsons = get_shangkoudai_jsons(dingzhiData);
  const xiakoudai_jsons = get_xiakoudai_jsons(dingzhiData);
  // const kadai_jsons = get_kadai_jsons(dingzhiData);
  const houcha_jsons = get_houcha_jsons(dingzhiData);
  const qianmenkou_jsons = get_qianmenkou_jsons(dingzhiData);
  const xiuzi_jsons = get_xiuzi_jsons(dingzhiData);
  // let all_list = appendix.concat(lingxing_jsons, shangkoudai_jsons, xiakoudai_jsons, kadai_jsons, houcha_jsons, qianmenkou_jsons, xiuzi_jsons);
  let all_list = appendix.concat(lingxing_jsons, shangkoudai_jsons, xiakoudai_jsons, houcha_jsons, qianmenkou_jsons, xiuzi_jsons);
  for(let i=0; i < all_list.length; i++){
    if(all_list[i].includes('null')){
      all_list.splice(i, 1);
    }
  }
  return all_list
}

function get_lingxing_jsons(selectedCrafts){
  let lingxing_jsons = [];
  ;
  const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===dayi_map.前门扣) || DY_DEF[dayi_map.前门扣];
  const ling_xing = selectedCrafts.find(item=>item.model_parent_id===dayi_map.领型) || DY_DEF[dayi_map.领型];
  const bo_tou_kuan = selectedCrafts.find(item=>item.model_parent_id===dayi_map.驳领宽) || DY_DEF[dayi_map.驳领宽];
  const cha_hua_yan = selectedCrafts.find(item=>item.model_parent_id===dayi_map.插花眼形) || DY_DEF[dayi_map.插花眼形];
  let qian_men_kou_id = qian_men_kou.model_id;
  if(qian_men_kou_id===dayi_map.单排暗门襟三粒扣){
    qian_men_kou_id = dayi_map.单排三粒扣;
  }
  if(qian_men_kou_id===dayi_map.单排暗门襟四粒扣){
    qian_men_kou_id = dayi_map.单排四粒扣;
  }
  if(qian_men_kou_id===dayi_map.双排六粒扣二){
    qian_men_kou_id = dayi_map.双排四粒扣二;
  }
  const ling_xing_id = ling_xing.model_id;
  const bo_tou_kuan_id = bo_tou_kuan.model_id;
  const cha_hua_yan_id = cha_hua_yan.model_id;
  if(ling_xing!==dayi_map.青果领) {
    lingxing_jsons.push(BASEURL + '/model/mdy/' + qian_men_kou_id + '_' + ling_xing_id + '_' + bo_tou_kuan_id + '_' + cha_hua_yan_id +'.json');
  }  
  lingxing_jsons.push(BASEURL + '/model/mdy/' + qian_men_kou_id + '_' + ling_xing_id + '_' + bo_tou_kuan_id + '.json');
  return lingxing_jsons;
}

function get_shangkoudai_jsons(selectedCrafts){
  let shangkoudai_jsons = [];
  const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===dayi_map.前门扣) || DY_DEF[dayi_map.前门扣];
  const shang_kou_dai = selectedCrafts.find(item=>item.model_parent_id===dayi_map.上口袋) || DY_DEF[dayi_map.上口袋];
  let qian_men_kou_id = qian_men_kou.model_id;
  const shang_kou_dai_id = shang_kou_dai.model_id;
  if(qian_men_kou_id===dayi_map.单排暗门襟三粒扣){
    qian_men_kou_id = dayi_map.单排三粒扣;
  }
  if(qian_men_kou_id===dayi_map.单排暗门襟四粒扣){
    qian_men_kou_id = dayi_map.单排四粒扣;
  }
  if(qian_men_kou_id===dayi_map.双排六粒扣二){
    qian_men_kou_id = dayi_map.双排四粒扣二;
  }
  if(qian_men_kou_id===dayi_map.双排六粒扣二){
    qian_men_kou_id = dayi_map.双排四粒扣二;
  }
  if(shang_kou_dai_id!==dayi_map.无手巾袋){
    shangkoudai_jsons.push(BASEURL + '/model/mdy/' + qian_men_kou_id + '_' + shang_kou_dai_id + '.json')
  }
  return shangkoudai_jsons
}

function get_xiakoudai_jsons(selectedCrafts){
  let xiakoudai_jsons = [];
  const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===dayi_map.前门扣) || DY_DEF[dayi_map.前门扣];
  const xia_kou_dai = selectedCrafts.find(item=>item.model_parent_id===dayi_map.下口袋) || DY_DEF[dayi_map.下口袋];
  const xia_kou_dai_id = xia_kou_dai.model_id;
  let qian_men_kou_id = qian_men_kou.model_id;
  if(qian_men_kou_id===dayi_map.单排暗门襟三粒扣){
    qian_men_kou_id = dayi_map.单排三粒扣;
  }
  if(qian_men_kou_id===dayi_map.单排暗门襟四粒扣){
    qian_men_kou_id = dayi_map.单排四粒扣;
  }
  if(qian_men_kou_id===dayi_map.双排六粒扣二){
    qian_men_kou_id = dayi_map.双排四粒扣二;
  }
  if(qian_men_kou_id===dayi_map.双排六粒扣二){
    qian_men_kou_id = dayi_map.双排四粒扣二;
  }
  xiakoudai_jsons.push(BASEURL + '/model/mdy/' + qian_men_kou_id + '_' + xia_kou_dai_id + '.json');
  return xiakoudai_jsons
}

// function get_kadai_jsons(selectedCrafts){
//   let kadai_jsons = [];
//   const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===dayi_map.前门扣);
//   const ka_dai = selectedCrafts.find(item=>item.model_parent_id===dayi_map.卡袋);
//   const ka_dai_id = ka_dai.model_id;
//   let qian_men_kou_id = qian_men_kou.model_id;
//   if(ka_dai_id!==dayi_map.无卡袋){
//     kadai_jsons.push(BASEURL + '/model/mdy/' + qian_men_kou_id + '_' + ka_dai_id + '.json');
//   }
//   return kadai_jsons
// }

function get_houcha_jsons(selectedCrafts){
  let houcha_jsons = [];
  const hou_cha = selectedCrafts.find(item=>item.model_parent_id===dayi_map.后衩) || DY_DEF[dayi_map.后衩];
  let hou_cha_id = hou_cha.model_id;
  houcha_jsons.push(BASEURL + '/model/mdy/'+ hou_cha_id + '.json');
  return houcha_jsons
}

function get_qianmenkou_jsons(selectedCrafts){
  let qianmenkou_jsons = [];
  const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===dayi_map.前门扣) || DY_DEF[dayi_map.前门扣];
  let qian_men_kou_id = qian_men_kou.model_id;

  if(qian_men_kou_id!==dayi_map.单排暗门襟三粒扣&&qian_men_kou_id!==dayi_map.单排暗门襟四粒扣){
    qianmenkou_jsons.push(BASEURL + '/model/mdy/'+ dayi_map.前门扣 + '_' + qian_men_kou_id +'.json');
    qianmenkou_jsons.push(BASEURL + '/model/mdy/'+ qian_men_kou_id + '_' + dayi_map.扣眼 + '.json');
  }
  if(qian_men_kou_id===dayi_map.双排六粒扣二){
    qian_men_kou_id = dayi_map.双排四粒扣二;
  }
  qianmenkou_jsons.push(BASEURL + '/model/mdy/'+ qian_men_kou_id + '.json');
  return qianmenkou_jsons
}

function get_xiuzi_jsons(selectedCrafts){
  let xiuzi_jsons = [];
  const xiu_xing = selectedCrafts.find(item=>item.model_parent_id===dayi_map.袖口形状) || DY_DEF[dayi_map.袖口形状];
  const niu_kou = selectedCrafts.find(item=>item.model_parent_id===dayi_map.袖纽扣) || DY_DEF[dayi_map.袖纽扣];
  const jian_ju = selectedCrafts.find(item=>item.model_parent_id===dayi_map.袖扣间距) || DY_DEF[dayi_map.袖扣间距];
  const fang_xiang = selectedCrafts.find(item=>item.model_parent_id===dayi_map.袖扣眼方向) || DY_DEF[dayi_map.袖扣眼方向];
  const xiu_xing_id = xiu_xing.model_id;
  const niu_kou_id = niu_kou.model_id;
  const jian_ju_id = jian_ju.model_id;
  const fang_xiang_id = fang_xiang.model_id;
  if(xiu_xing_id!==dayi_map.内折袖口&&xiu_xing_id!==dayi_map.尖角袖袢袖口){
    xiuzi_jsons.push(BASEURL + '/model/mdy/'+ niu_kou_id + '_' + jian_ju_id + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mdy/'+ niu_kou_id + '_' + jian_ju_id + '_' + fang_xiang_id + '.json');
  }
  if(xiu_xing_id===dayi_map.尖角袖袢袖口){
    xiuzi_jsons.push(BASEURL + '/model/mdy/'+ dayi_map.尖角袖袢袖口 + '_' + dayi_map.袖袢 + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mdy/'+ dayi_map.尖角袖袢袖口 + '_' + dayi_map.扣子 + '.json');
  }
  xiuzi_jsons.push(BASEURL + '/model/mdy/'+ xiu_xing_id + '.json');
  return xiuzi_jsons
}

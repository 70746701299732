import { BASEURL_SDK, BASEURL_CUSTOM } from './Global';
export async function init() {
  const resp = await fetch(BASEURL_SDK+'/get-sdk-url/sdk@1.1.2.js')
  const json = await resp.json()
  const { url } = json
  const httpsurl = url.replace('http', 'https')
  const script = document.createElement('script')
  
  try {
    await new Promise(
      (resolve, reject) => {
        script.onload = resolve
        script.onerror = reject
        script.src = httpsurl
        // script.src = '/sdk.js'
        document.body.appendChild(script)
      },
    )
  } catch(e) {
    throw new Error('init sdk fail!')
  }
  return global.__3d_sdk__
}
export const dataURLtoFile=(dataurl)=> {

  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr],`${new Date().getTime()}.jpg`, {type:mime});
}
export const upLoadFile=(ImageFile,token)=> {
 return new Promise((resolve,reject)=>{
  let formData = new FormData();
  formData.append("file", ImageFile);
  formData.append("file_prefix", "hanbang");
  let request = new XMLHttpRequest();

  
  request.open("POST", `${BASEURL_CUSTOM}/store/file/upload`);
  request.setRequestHeader("authorization", token);

  request.onload = function(e) {
    if (this.status === 200) {
      
          resolve(e.target.response);
     
    // myBlob is now the blob that the object URL pointed to.
    } else {
      reject(e);
    }
  
   // myBlob is now the blob that the object URL pointed to.
   }
  request.send(formData);
 });
  
}
export const csUpLoadFile=(ImageFile, appid, openid)=> {
  return new Promise((resolve,reject)=>{
   let formData = new FormData();
  //  formData.append("appid", appid);
  //  formData.append("openid", openid);
   formData.append("file", ImageFile);
   formData.append("file_prefix", "caisheng");
   console.log(appid);
   console.log(openid);
   console.log(ImageFile);
   
   let request = new XMLHttpRequest();
 
   
   request.open("POST", `${BASEURL_CUSTOM}/api/file/upload`);
   request.setRequestHeader("appid", appid);
   request.setRequestHeader("openid", openid);
   request.onload = function(e) {
     ;
     if (this.status === 200) {
           
           resolve(e.target.response);
      
     // myBlob is now the blob that the object URL pointed to.
     } else {
       reject(e);
     }
   
    // myBlob is now the blob that the object URL pointed to.
    }
   request.send(formData);
  });
   
 }
export const getBlobWithUrl=(url)=>{
  return new Promise((resolve,reject)=>{
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function(e) {
    if (this.status === 200) {
       let blob = this.response;let reader = new FileReader();
       reader.onload=function(e) {
           resolve(e.target.result);
       }
       reader.readAsDataURL(blob);
   // myBlob is now the blob that the object URL pointed to.
   }
 };
 xhr.send();
  });
 
}

export function GetPathParams() {
  console.log("页面URL: ",window.location.href);
  let vars = window.location.search.split("=");
  let params = null;
  if(vars[1]){
    params = JSON.parse(decodeURIComponent(vars[1]));
  }else{
    params = {"category_id":"1002","appid":"wx069c4d7574501e36","isVip":false,"activeIndex2":[0,0,0,0,0,0],"fabricIndex":0}
  }
  return params
}

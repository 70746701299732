import { BASEURL } from '../Global.js'
import DefaultGongYi from './defaultGongYi';
import { get_select_crafts_work } from './common.js';

const XXKU_DEF = DefaultGongYi.MXXK;

const xixianku_map = {
  '前片形式' : "7021",
  '后片形式' : "7022",
  '前袋形式' : "7023",
  '前褶形式' : "7025",
  '后省形式' : "7026",
  '门襟形式' : "7027",
  '腰形式' : "7028",
  '脚口形式' : "7029",
  '后袋形式' : "7109",
  '左后袋盖' : "7110",
  '右后袋盖' : "7111",
  '右币袋' : "7112",
  
  "正常腰": "7280",
  "后片正常": "7220",
  "后片带后桥": "7221",
  "后单省": "7260",
  "后双省": "7261",
  "后桥无省": "7262",
  "普通门襟": "7270",
  "无后袋": "7894",
  "无币袋": "7930",
  "右后袋1": "7901",
  "右后袋2": "7902",
  "右后袋3": "7903",
  "右后袋4": "7904",
  "右后袋5": "7905",
  "右后袋6": "7906",
  "无袋盖左": "7910",
  "无袋盖右": "7920",

  "双双开拉链": "7899",
  "双单开拉链": "7900",
  "右双开拉链": "7905",
  "右单开拉链": "7906",
  // 以下是自定义的

  '腰鼻' :          "yaobi",
  '门襟扣':         "menjinkou",

};
export const get_XiuXianKu_Jsons = (craftWorks, indexArr) => {
  const appendix = [
    BASEURL + '/model/mxxk/' + xixianku_map.门襟扣 + '.json',
    BASEURL + '/model/mxxk/' + xixianku_map.腰鼻 + '.json',
    BASEURL + '/model/mxxk/' + xixianku_map.普通门襟 + '.json',
  ]
  const dingzhiData = get_select_crafts_work(craftWorks, XXKU_DEF);
  const yaotou_jsons = get_yao_tou_jsons(dingzhiData);
  const qian_koudai_jsons = get_qian_koudai_jsons(dingzhiData);
  const hou_koudai_jsons = get_hou_koudai_jsons(dingzhiData);
  const jiaokou_jsons = get_jiaokou_jsons(dingzhiData);
  const dashen_jsons = get_da_shen_jsons(dingzhiData);
  const bidou_jsons = get_bidou_jsons(dingzhiData);
  const houdaigai_jsons = get_hou_daigai_jsons(dingzhiData);
  let all_list = appendix.concat(dashen_jsons, yaotou_jsons, qian_koudai_jsons, hou_koudai_jsons, jiaokou_jsons, bidou_jsons, houdaigai_jsons);
  for(let i=0; i < all_list.length; i++){
    if(all_list[i].includes('null')){
      all_list.splice(i, 1);
    }
  }
  return all_list
}

function get_da_shen_jsons(selectedCrafts){
  let da_shen_jsons = [];
  const qian_pian = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.前片形式) || XXKU_DEF[xixianku_map.前片形式];
  const hou_pian = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.后片形式) || XXKU_DEF[xixianku_map.后片形式];
  const qian_zhe = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.前褶形式) || XXKU_DEF[xixianku_map.前褶形式];
  const hou_sheng = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.后省形式) || XXKU_DEF[xixianku_map.后省形式];

  const hou_pian_id = hou_pian.model_id;
  const qian_zhe_id = qian_zhe.model_id;
  const hou_sheng_id = hou_sheng.model_id;
  da_shen_jsons.push(BASEURL + '/model/mxxk/7210_' + qian_zhe_id + '.json');
  if(hou_pian_id===xixianku_map.后片正常 && hou_sheng_id!==xixianku_map.后桥无省) {
    da_shen_jsons.push(BASEURL + '/model/mxxk/7220_' + hou_sheng_id + '.json');
  } else {
    da_shen_jsons.push(BASEURL + '/model/mxxk/7221_' + xixianku_map.后桥无省 + '.json');
  }
  return da_shen_jsons;
}

function get_yao_tou_jsons(selectedCrafts){
  let yao_tou_jsons = [];
  const yao_tou = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.腰形式) || XXKU_DEF[xixianku_map.腰形式];
  const yao_tou_id = yao_tou.model_id;
  if(yao_tou_id!==xixianku_map.正常腰){
    yao_tou_jsons.push(BASEURL + '/model/mxxk/' + yao_tou_id + '.json');
    yao_tou_jsons.push(BASEURL + '/model/mxxk/' + yao_tou_id + '_child.json');
  }else {
    yao_tou_jsons.push(BASEURL + '/model/mxxk/' + yao_tou_id + '.json');
  }
  return yao_tou_jsons;
}

function get_qian_koudai_jsons(selectedCrafts){
  let qian_kou_dai_jsons = [];
  const qian_kou_dai = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.前袋形式) || XXKU_DEF[xixianku_map.前袋形式];
  let qian_kou_dai_id = qian_kou_dai.model_id;
  qian_kou_dai_jsons.push(BASEURL + '/model/mxxk/' + qian_kou_dai_id + '.json')
  return qian_kou_dai_jsons
}

function get_hou_koudai_jsons(selectedCrafts){
  let hou_koudai_jsons = [];
  const hou_kou_dai = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.后袋形式) || XXKU_DEF[xixianku_map.后袋形式];
  let hou_kou_dai_id = hou_kou_dai.model_id;
  if(hou_kou_dai_id===xixianku_map.无后袋) {
    return hou_koudai_jsons
  } else if(hou_kou_dai_id===xixianku_map.双单开拉链||hou_kou_dai_id===xixianku_map.双双开拉链||
    hou_kou_dai_id===xixianku_map.右单开拉链||hou_kou_dai_id===xixianku_map.右双开拉链){
    hou_koudai_jsons.push(BASEURL + '/model/mxxk/'+ hou_kou_dai_id + '.json');
    hou_koudai_jsons.push(BASEURL + '/model/mxxk/'+ hou_kou_dai_id + '_child.json');
    return hou_koudai_jsons
  } else {
    hou_koudai_jsons.push(BASEURL + '/model/mxxk/'+ hou_kou_dai_id + '.json');
    return hou_koudai_jsons
  }
}

function get_jiaokou_jsons(selectedCrafts){
  let jiaokou_jsons = [];
  const jiaokou = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.脚口形式) || XXKU_DEF[xixianku_map.脚口形式];
  let jiaokou_id = jiaokou.model_id;
  jiaokou_jsons.push(BASEURL + '/model/mxxk/'+ jiaokou_id + '.json')
  return jiaokou_jsons
}

function get_bidou_jsons(selectedCrafts){
  let bidou_jsons = [];
  const bidou = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.右币袋) || XXKU_DEF[xixianku_map.右币袋];
  let bidou_id = bidou.model_id;
  if(bidou_id===xixianku_map.无币袋) {
    return []
  }
  bidou_jsons.push(BASEURL + '/model/mxxk/'+ bidou_id + '.json')
  return bidou_jsons
}

function get_hou_daigai_jsons(selectedCrafts){
  let hou_daigai_jsons = [];
  const hou_kou_dai = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.后袋形式) || XXKU_DEF[xixianku_map.后袋形式];
  const hou_l_dai = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.左后袋盖) || XXKU_DEF[xixianku_map.左后袋盖];
  const hou_r_dai = selectedCrafts.find(item=>item.model_parent_id===xixianku_map.右后袋盖) || XXKU_DEF[xixianku_map.右后袋盖];

  let hou_kou_dai_id = hou_kou_dai.model_id;
  let hou_l_dai_id = hou_l_dai.model_id;
  let hou_r_dai_id = hou_r_dai.model_id;
  if(hou_kou_dai_id === xixianku_map.无后袋) {
    return []
  }
  if(hou_kou_dai_id!==xixianku_map.右后袋1 && hou_kou_dai_id!==xixianku_map.右后袋2 && hou_kou_dai_id!==xixianku_map.右后袋3
    && hou_kou_dai_id!==xixianku_map.右后袋4 && hou_kou_dai_id!==xixianku_map.右后袋5
    && hou_kou_dai_id!==xixianku_map.右后袋6) {
      if(hou_l_dai_id!==xixianku_map.无袋盖左) {
        hou_daigai_jsons.push(BASEURL + '/model/mxxk/'+ hou_l_dai_id + '.json')
      }
      if(hou_r_dai_id!==xixianku_map.无袋盖右) {
        hou_daigai_jsons.push(BASEURL + '/model/mxxk/'+ hou_r_dai_id + '.json')
      }
    }
  if(hou_kou_dai_id===xixianku_map.右后袋1 || hou_kou_dai_id===xixianku_map.右后袋2 || hou_kou_dai_id===xixianku_map.右后袋3
    || hou_kou_dai_id===xixianku_map.右后袋4 || hou_kou_dai_id===xixianku_map.右后袋5
    || hou_kou_dai_id===xixianku_map.右后袋6) {
      if(hou_r_dai_id!==xixianku_map.无袋盖右) {
        hou_daigai_jsons.push(BASEURL + '/model/mxxk/'+ hou_r_dai_id + '.json')
      }
    }
  return hou_daigai_jsons
}


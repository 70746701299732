import { BASEURL } from '../Global.js'
import DefaultGongYi from './defaultGongYi';
import { get_select_crafts_work } from './common.js';

const XIKU_DEF = DefaultGongYi.MXK;

const xiku_map = {
  '款式' :        "3002",
  '腰头'  :       "3003",
  '前口袋':       "3004",
  '后口袋':       "3005",
  '脚口' :       "3006",
  '无褶裤' :      "3007",
  '双圆宝剑头':   "3013",
  '双尖宝剑头':   "3014",
  '平腰头挂钩':   "3015",
  '平腰头纽扣':   "3016",
  '斜插袋':       "3017",
  '直插袋' :      "3018",
  '月牙袋':       "3019",
  '七字袋' :      "3020",
  '圆宝剑头' :    "3010",
  '方宝剑头':     "3011",
  '尖宝剑头' :    "3012",
  '双开线钉扣' :  "3021",
  '单开线钉扣':   "3023",
  '棱形袋盖明扣': "3026",
  '无口袋' :      "3027",
  '脚口外翻卷边': "3030",
  '腰扣'  :       "3031",
  '腰扣眼' :      "3032",

  // 以下是自定义的
  '棱形袋盖明扣子': "3033",
  '棱形袋盖明扣眼': "3034",
  '双开线钉扣子':   "3035",
  '双开线钉扣眼' :  "3036",
  '单开线钉扣子' :  "3037",
  '单开线钉扣眼':   "3038",
  '长腰腰扣':       "3039",
  '长腰腰扣眼' :    "3040",
  '平腰头' :        "3041",
  '后片' :          "hp",
  '填充' :          "fill",
  '腰鼻' :          "yb",
};
export const get_Xiku_Jsons = (craftWorks, indexArr) => {
  const appendix = [
    BASEURL + '/model/mxk/' + xiku_map.后片 + '.json',
    BASEURL + '/model/mxk/' + xiku_map.填充 + '.json',
    BASEURL + '/model/mxk/' + xiku_map.腰鼻 + '.json',
  ]
  const dingzhiData = get_select_crafts_work(craftWorks, XIKU_DEF);
  const yaotou_jsons = get_yao_tou_jsons(dingzhiData);
  const qian_koudai_jsons = get_qian_koudai_jsons(dingzhiData);
  const hou_koudai_jsons = get_hou_koudai_jsons(dingzhiData);
  const jiaokou_jsons = get_jiaokou_jsons(dingzhiData);
  let all_list = appendix.concat(yaotou_jsons, qian_koudai_jsons, hou_koudai_jsons, jiaokou_jsons);
  for(let i=0; i < all_list.length; i++){
    if(all_list[i].includes('null')){
      all_list.splice(i, 1);
    }
  }
  return all_list
}

function get_yao_tou_jsons(selectedCrafts){
  let yao_tou_jsons = [];
  const yao_tou = selectedCrafts.find(item=>item.model_parent_id===xiku_map.腰头) || XIKU_DEF[xiku_map.腰头];
  const yao_tou_id = yao_tou.model_id;
  if(yao_tou_id===xiku_map.平腰头挂钩){
    yao_tou_jsons.push(BASEURL + '/model/mxk/' + xiku_map.平腰头 + '.json');
  }
  else if(yao_tou_id===xiku_map.平腰头纽扣){
    yao_tou_jsons.push(BASEURL + '/model/mxk/' + xiku_map.平腰头 + '.json');
    yao_tou_jsons.push(BASEURL + '/model/mxk/' + xiku_map.腰扣 + '.json');
    yao_tou_jsons.push(BASEURL + '/model/mxk/' + xiku_map.腰扣眼 + '.json');
  }
  else if(yao_tou_id===xiku_map.圆宝剑头||yao_tou_id===xiku_map.方宝剑头||yao_tou_id===xiku_map.尖宝剑头||yao_tou_id===xiku_map.双圆宝剑头||yao_tou_id===xiku_map.双尖宝剑头){
    yao_tou_jsons.push(BASEURL + '/model/mxk/' + yao_tou_id + '.json');
    yao_tou_jsons.push(BASEURL + '/model/mxk/' + xiku_map.长腰腰扣 + '.json');
    yao_tou_jsons.push(BASEURL + '/model/mxk/' + xiku_map.长腰腰扣眼 + '.json');
  }else {
    yao_tou_jsons.push(BASEURL + '/model/mxk/' + yao_tou_id + '.json');
  }
  return yao_tou_jsons;
}

function get_qian_koudai_jsons(selectedCrafts){
  let qian_kou_dai_jsons = [];
  const qian_kou_dai = selectedCrafts.find(item=>item.model_parent_id===xiku_map.前口袋) || XIKU_DEF[xiku_map.前口袋];
  const kuan_shi = selectedCrafts.find(item=>item.model_parent_id===xiku_map.款式) || XIKU_DEF[xiku_map.款式];
  let qian_kou_dai_id = qian_kou_dai.model_id;
  let kuan_shi_id = kuan_shi.model_id;
  if(qian_kou_dai_id===xiku_map.斜插袋||qian_kou_dai_id===xiku_map.直插袋){
    qian_kou_dai_jsons.push(BASEURL + '/model/mxk/' + kuan_shi_id + '_' + qian_kou_dai_id + '.json')
  }else{
    qian_kou_dai_jsons.push(BASEURL + '/model/mxk/' + qian_kou_dai_id + '.json')
  }
  return qian_kou_dai_jsons
}

function get_hou_koudai_jsons(selectedCrafts){
  let hou_koudai_jsons = [];
  const hou_kou_dai = selectedCrafts.find(item=>item.model_parent_id===xiku_map.后口袋) || XIKU_DEF[xiku_map.后口袋];
  let hou_kou_dai_id = hou_kou_dai.model_id;
  if(hou_kou_dai_id===xiku_map.双开线钉扣){
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ hou_kou_dai_id + '.json');
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ xiku_map.双开线钉扣子 + '.json');
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ xiku_map.双开线钉扣眼 + '.json');
  }else if(hou_kou_dai_id===xiku_map.单开线钉扣){
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ hou_kou_dai_id + '.json');
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ xiku_map.单开线钉扣子 + '.json');
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ xiku_map.单开线钉扣眼 + '.json');
  }else if(hou_kou_dai_id===xiku_map.棱形袋盖明扣){
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ hou_kou_dai_id + '.json');
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ xiku_map.棱形袋盖明扣子 + '.json');
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ xiku_map.棱形袋盖明扣眼 + '.json');
  }else if(hou_kou_dai_id===xiku_map.无口袋){

  }else{
    hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ hou_kou_dai_id + '.json');
  }
  // hou_koudai_jsons.push(BASEURL + '/model/mxk/'+ hou_bei_id + '_' + xia_bai_id + '.json');
  return hou_koudai_jsons
}

function get_jiaokou_jsons(selectedCrafts){
  let jiaokou_jsons = [];
  const jiaokou = selectedCrafts.find(item=>item.model_parent_id===xiku_map.脚口) || XIKU_DEF[xiku_map.脚口];
  let jiaokou_id = jiaokou.model_id;
  if(jiaokou_id===xiku_map.脚口外翻卷边){
    jiaokou_jsons.push(BASEURL + '/model/mxk/'+ jiaokou_id + '.json')
  }
  return jiaokou_jsons
}
// function get_menjin_jsons(selectedCrafts){
//   let menjin_jsons = [];
//   const qianmenjin = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.门襟);
//   const xiabai =selectedCrafts.find(item=>item.model_parent_id===chenshan_map.下摆);
//   let men_jin_id = qianmenjin.model_id;
//   let xia_bai_id = xiabai.model_id;
//   if(men_jin_id===chenshan_map.明门襟){
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.明门襟 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '_' +chenshan_map.扣眼 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
//   }
//   if(men_jin_id===chenshan_map.软门襟){
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '_' +chenshan_map.扣眼 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
//   }
//   if(men_jin_id===chenshan_map.暗门襟){
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.明门襟 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
//   }
//   if(men_jin_id===chenshan_map.多褶明门襟){
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.明门襟 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '_' +chenshan_map.扣眼 + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.多褶明门襟 + '_' +xia_bai_id + '.json');
//     menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
//   }
//   return menjin_jsons
// }

// function get_xiuzi_jsons(selectedCrafts){
//   let xiuzi_jsons = [];
//   let zheng_chang_xiu_jsons = [];
//   let fa_xiu_jsons = [];
//   const xiu_kou = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.袖型);
//   const xiu_kou_id = xiu_kou.model_id;
//   zheng_chang_xiu_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.正常袖 + '.json');
//   zheng_chang_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '.json');
//   zheng_chang_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '_' + chenshan_map.扣子 + '.json');
//   zheng_chang_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '_' + chenshan_map.扣子 + chenshan_map.扣眼 + '.json');  
  
//   fa_xiu_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.法袖 + '.json')
//   fa_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '.json');
//   fa_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '_' + chenshan_map.扣子 + '.json');
//   fa_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '_' + chenshan_map.扣子 + chenshan_map.扣眼 + '.json');
  
//   if(xiu_kou_id === chenshan_map.弧形单扣){
//     xiuzi_jsons = zheng_chang_xiu_jsons
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.弧形袖 + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.弧形单扣 + '_' + chenshan_map.扣子 + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.一眼两扣 + '_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
//   }
//   if(xiu_kou_id === chenshan_map.绅士袖){
//     xiuzi_jsons = zheng_chang_xiu_jsons
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + xiu_kou_id + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.两眼两扣 + '_' + chenshan_map.扣子 + '.json');
//   }
//   if(xiu_kou_id === chenshan_map.六角一眼两扣||xiu_kou_id === chenshan_map.圆角一眼两扣){
//     xiuzi_jsons = zheng_chang_xiu_jsons
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + xiu_kou_id + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.一眼两扣 + '_' + chenshan_map.扣子 + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.一眼两扣 + '_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
//   }
//   if(xiu_kou_id === chenshan_map.六角两眼两扣||xiu_kou_id === chenshan_map.翻折梯形袖||xiu_kou_id === chenshan_map.圆角两眼两扣){
//     xiuzi_jsons = zheng_chang_xiu_jsons
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + xiu_kou_id + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.两眼两扣 + '_' + chenshan_map.扣子 + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.两眼两扣 + '_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
//   }
//   if(xiu_kou_id === chenshan_map.直角翻折法袖||xiu_kou_id === chenshan_map.圆角翻折法袖||xiu_kou_id === chenshan_map.六角翻折法袖){
//     xiuzi_jsons = fa_xiu_jsons
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + xiu_kou_id + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.六角翻折法袖 + '_' + chenshan_map.扣子 + '.json');
//     xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.六角翻折法袖 + '_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
//   }
//   return xiuzi_jsons
// }

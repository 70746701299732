import { BASEURL_CUSTOM } from '../Global.js'
export async function getDingzhiInfo(payload){
  const response = await fetch(BASEURL_CUSTOM + `/api/public/custom/data/${payload.category_id}`,
    { 
      method:'GET',
      headers:{
        'Content-Type':'application/json;',
        'appid': payload.appid,
      },
      mode:'cors',
      cache:'default'
    })
  .then(
    async res => {
      if(res.status === 200) {
        const result = await res.json();
        console.log("获取定制信息：", result);
        return result;
      }
    }
  )
  return response.data;
}
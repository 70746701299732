import { BASEURL } from '../Global.js'
import DefaultGongYi from './defaultGongYi';
import { get_select_crafts_work } from './common.js';

const JK_DEF = DefaultGongYi.MJK;
const jiake_map = {
  '领型':           "5002",
  '前片':          "5003",
  '门襟':          "5004",
  '口袋':           "5005",
  '下摆':           "5006",
  '后背':           "5007",
  '袖口':           "5008",
  '对襟拉链门襟':    "5014",
  '斜双开线带拉链':  "5017",
  '折边袖口':       "5025",

  // 以下是自定义的
  '拉链':           "5029",
  '拉链扣':         "5030",
  '袖扣':           "5032",
};
export const get_Jiake_Jsons = (craftWorks, indexArr) => {
  const appendix = [
  ]
  const dingzhiData = get_select_crafts_work(craftWorks, JK_DEF);
  const all_jsons = get_all_jsons(dingzhiData);
  let all_list = appendix.concat(all_jsons);
  for(let i=0; i < all_list.length; i++){
    if(all_list[i].includes('null')){
      all_list.splice(i, 1);
    }
  }
  return all_list
}

function get_all_jsons(selectedCrafts){
  let jia_ke_jsons = [];
  const qian_pian = selectedCrafts.find(item=>item.model_parent_id===jiake_map.前片);

  const ling_xing = selectedCrafts.find(item=>item.model_parent_id===jiake_map.领型);
  const men_jin = selectedCrafts.find(item=>item.model_parent_id===jiake_map.门襟);
  const kou_dai = selectedCrafts.find(item=>item.model_parent_id===jiake_map.口袋);
  const xia_bai = selectedCrafts.find(item=>item.model_parent_id===jiake_map.下摆);
  const hou_bei = selectedCrafts.find(item=>item.model_parent_id===jiake_map.后背);
  const xiu_kou = selectedCrafts.find(item=>item.model_parent_id===jiake_map.袖口);
  let qian_pian_id = qian_pian.model_id;
  let ling_xing_id = ling_xing.model_id;
  let men_jin_id = men_jin.model_id;
  let kou_dai_id = kou_dai.model_id;
  let xia_bai_id = xia_bai.model_id;
  let hou_bei_id = hou_bei.model_id;
  let xiu_kou_id = xiu_kou.model_id;

  jia_ke_jsons.push(BASEURL + '/model/mjk/' + ling_xing_id + '.json');
  jia_ke_jsons.push(BASEURL + '/model/mjk/' + qian_pian_id + '.json');
  jia_ke_jsons.push(BASEURL + '/model/mjk/' + men_jin_id + '.json');
  jia_ke_jsons.push(BASEURL + '/model/mjk/' + kou_dai_id + '.json');
  jia_ke_jsons.push(BASEURL + '/model/mjk/' + xiu_kou_id + '.json');
  if(men_jin_id===jiake_map.对襟拉链门襟){
    jia_ke_jsons.push(BASEURL + '/model/mjk/' + jiake_map.对襟拉链门襟 + '.json');
    jia_ke_jsons.push(BASEURL + '/model/mjk/' + jiake_map.对襟拉链门襟 + '_' + jiake_map.拉链扣 + '.json');
  }
  if(kou_dai_id===jiake_map.斜双开线带拉链){
    jia_ke_jsons.push(BASEURL + '/model/mjk/' + jiake_map.斜双开线带拉链 + '.json');
    jia_ke_jsons.push(BASEURL + '/model/mjk/' + jiake_map.斜双开线带拉链 + '_' + jiake_map.拉链 + '.json');
    jia_ke_jsons.push(BASEURL + '/model/mjk/' + jiake_map.斜双开线带拉链 + '_' + jiake_map.拉链扣 + '.json');
  }
  jia_ke_jsons.push(BASEURL + '/model/mjk/' + xia_bai_id + '.json');
  jia_ke_jsons.push(BASEURL + '/model/mjk/' + hou_bei_id + '.json');
  if(xiu_kou_id!==jiake_map.折边袖口){
    jia_ke_jsons.push(BASEURL + '/model/mjk/' + jiake_map.袖扣 + '.json');
  }
  return jia_ke_jsons;
}


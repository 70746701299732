import { BASEURL } from '../Global.js'
import { get_select_crafts_work } from './common';
import DefaultGongYi from './defaultGongYi';
import { GetPathParams } from '../util.js';

const xifu_map = {
  '前款式':'1002',
  '领型':'1003',
  '驳头宽': '1004',
  '插花眼形': '1005',
  '插花眼色': '1006',
  '胸斗': '1007',
  '腰斗': '1008',
  '卡袋': '1009',
  '后衩': '1010',
  '袖扣': '1011',
  '袖扣眼方向': '1012',
  '袖扣眼间距': '1013',
  '袖扣三粒扣': '1067',
  '袖扣四粒扣' : '1066',
  '袖扣五粒扣': '1068',
  '单排一粒扣': '1014',
  '单排二粒扣': '1015',
  '双排二扣一': '1017',
  '双排四扣一': '1018',
  '双排四扣二': '1019',
  '双排六扣二': '1020',
  '双排六扣三': '1021',
  '无手巾袋': '1050',
  '无卡袋': '1058',
  '双开衩': '1064',
  '双开防开衩': '1065',
};

const xifu_default = DefaultGongYi.MXF;
export const get_Xifu_Jsons = (craftWorks, indexArr) => {
  const appendix = [
    BASEURL + '/model/mxf/ld.json',
    BASEURL + '/model/mxf/cs.json',
  ]
  const dingzhiData = get_select_crafts_work(craftWorks, xifu_default);
  
  const lingxing_jsons = get_lingxing_jsons(dingzhiData);
  const shangkoudai_jsons = get_shangkoudai_jsons(dingzhiData);
  const xiakoudai_jsons = get_xiakoudai_jsons(dingzhiData);
  const kadai_jsons = get_kadai_jsons(dingzhiData);
  const houcha_jsons = get_houcha_jsons(dingzhiData);
  const qianmenkou_jsons = get_qianmenkou_jsons(dingzhiData);
  const xiuzi_jsons = get_xiuzi_jsons(dingzhiData);
  let all_list = appendix.concat(lingxing_jsons, shangkoudai_jsons, xiakoudai_jsons, kadai_jsons, houcha_jsons, qianmenkou_jsons, xiuzi_jsons);
  for(let i=0; i < all_list.length; i++){
    if(all_list[i].includes('null')){
      all_list.splice(i, 1);
    }
  }
  return all_list
}

function get_lingxing_jsons(selectedCrafts){
  const lingxing_jsons = [];
  try {
    const qian_men_kou = selectedCrafts.find(item => item.model_parent_id===xifu_map.前款式) || xifu_default[xifu_map.前款式];
    const ling_xing = selectedCrafts.find(item => item.model_parent_id===xifu_map.领型) || xifu_default[xifu_map.领型];
    const bo_tou_kuan = selectedCrafts.find(item => item.model_parent_id===xifu_map.驳头宽) || xifu_default[xifu_map.驳头宽];
    const cha_hua_yan = selectedCrafts.find(item => item.model_parent_id===xifu_map.插花眼形) || xifu_default[xifu_map.插花眼形];
    let qian_men_kou_id = qian_men_kou.model_id;
    if(qian_men_kou_id===xifu_map.单排二粒扣){
      qian_men_kou_id = xifu_map.单排一粒扣;
    }
    if(qian_men_kou_id===xifu_map.双排四扣一){
      qian_men_kou_id = xifu_map.双排二扣一;
    }
    if(qian_men_kou_id===xifu_map.双排六扣二){
      qian_men_kou_id = xifu_map.双排四扣二;
    }
    const ling_xing_id = ling_xing.model_id;
    const bo_tou_kuan_id = bo_tou_kuan.model_id;
    const cha_hua_yan_id = cha_hua_yan.model_id;

    if(ling_xing !== xifu_map.单排一粒扣) { // 好像写错了 ling_xing  ？ ling_xing_id ？ qian_men_kou_id
      lingxing_jsons.push(BASEURL + '/model/mxf/' + qian_men_kou_id + '_' + ling_xing_id + '_' + bo_tou_kuan_id + '_' + cha_hua_yan_id +'.json');
    }  
    lingxing_jsons.push(BASEURL + '/model/mxf/' + qian_men_kou_id + '_' + ling_xing_id + '_' + bo_tou_kuan_id + '.json');
  } catch (e) {
    // console.error('=====get_lingxing_jsons=====', e)
  }
  return lingxing_jsons;
}

function get_shangkoudai_jsons(selectedCrafts){
  const shangkoudai_jsons = [];
  try {
    const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===xifu_map.前款式) || xifu_default[xifu_map.前款式];
    const shang_kou_dai = selectedCrafts.find(item=>item.model_parent_id===xifu_map.胸斗) || xifu_default[xifu_map.胸斗];
    let qian_men_kou_id = qian_men_kou.model_id;
    const shang_kou_dai_id = shang_kou_dai.model_id;
    if(qian_men_kou_id===xifu_map.单排二粒扣){
      qian_men_kou_id = xifu_map.单排一粒扣;
    }
    if(qian_men_kou_id===xifu_map.双排四扣一){
      qian_men_kou_id = xifu_map.双排二扣一;
    }
    if(qian_men_kou_id===xifu_map.双排六扣二){
      qian_men_kou_id = xifu_map.双排四扣二;
    }
    if(shang_kou_dai_id!==xifu_map.无手巾袋){
      shangkoudai_jsons.push(BASEURL + '/model/mxf/' + qian_men_kou_id + '_' + shang_kou_dai_id + '.json')
    }
  } catch (e) {
    // console.error('=====get_shangkoudai_jsons=====', e)
  }
  return shangkoudai_jsons
}

function get_xiakoudai_jsons(selectedCrafts){
  const xiakoudai_jsons = [];
  try {
    const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===xifu_map.前款式) || xifu_default[xifu_map.前款式];
    const xia_kou_dai = selectedCrafts.find(item=>item.model_parent_id===xifu_map.腰斗) || xifu_default[xifu_map.腰斗];
    const xia_kou_dai_id = xia_kou_dai.model_id;
    let qian_men_kou_id = qian_men_kou.model_id;
    if(qian_men_kou_id===xifu_map.单排二粒扣){
      qian_men_kou_id = xifu_map.单排一粒扣;
    }
    if(qian_men_kou_id===xifu_map.双排四扣一){
      qian_men_kou_id = xifu_map.双排二扣一;
    }
    if(qian_men_kou_id===xifu_map.双排六扣二){
      qian_men_kou_id = xifu_map.双排四扣二;
    }
    xiakoudai_jsons.push(BASEURL + '/model/mxf/' + qian_men_kou_id + '_' + xia_kou_dai_id + '.json');
  } catch (e) {
    // console.error('=====get_xiakoudai_jsons=====', e)
  }
  return xiakoudai_jsons
}

function get_kadai_jsons(selectedCrafts){
  const kadai_jsons = [];
  try {
    const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===xifu_map.前款式) || xifu_default[xifu_map.前款式];
    const ka_dai = selectedCrafts.find(item=>item.model_parent_id===xifu_map.卡袋) || xifu_default[xifu_map.卡袋];
    const ka_dai_id = ka_dai.model_id;
    let qian_men_kou_id = qian_men_kou.model_id;
    if(qian_men_kou_id===xifu_map.单排二粒扣){
      qian_men_kou_id = xifu_map.单排一粒扣;
    }
    if(qian_men_kou_id===xifu_map.双排四扣一){
      qian_men_kou_id = xifu_map.双排二扣一;
    }
    if(qian_men_kou_id===xifu_map.双排六扣二){
      qian_men_kou_id = xifu_map.双排四扣二;
    }
    if(ka_dai_id!==xifu_map.无卡袋){
      kadai_jsons.push(BASEURL + '/model/mxf/' + qian_men_kou_id + '_' + ka_dai_id + '.json');
    }
  } catch (e) {
    // console.error('=====get_kadai_jsons=====', e)
  }
  return kadai_jsons
}

function get_houcha_jsons(selectedCrafts){
  const houcha_jsons = [];
  try {
    const hou_cha = selectedCrafts.find(item=>item.model_parent_id===xifu_map.后衩) || xifu_default[xifu_map.后衩];  // 汉邦默认双开叉
    let hou_cha_id = hou_cha.model_id;
    if(hou_cha_id===xifu_map.双开防开衩){
      hou_cha_id = xifu_map.双开衩;
    }
    houcha_jsons.push(BASEURL + '/model/mxf/'+ hou_cha_id + '.json');
  } catch (e) {
    // console.error('=====get_houcha_jsons=====', e)
  }
  return houcha_jsons
}

function get_qianmenkou_jsons(selectedCrafts){
  const qianmenkou_jsons = [];
  try {
    const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===xifu_map.前款式) || xifu_default[xifu_map.前款式];
    let qian_men_kou_id = qian_men_kou.model_id;
    if(qian_men_kou_id===xifu_map.单排二粒扣){
      qianmenkou_jsons.push(BASEURL + '/model/mxf/1015_1074.json');
      qianmenkou_jsons.push(BASEURL + '/model/mxf/1015_1073.json');
      qian_men_kou_id = xifu_map.单排一粒扣;
      qianmenkou_jsons.push(BASEURL + '/model/mxf/'+ qian_men_kou_id + '.json');
      return qianmenkou_jsons
    }
    if(qian_men_kou_id===xifu_map.双排四扣一){
      qianmenkou_jsons.push(BASEURL + '/model/mxf/1018_1073.json');
      qian_men_kou_id = xifu_map.双排二扣一;
    }
    if(qian_men_kou_id===xifu_map.双排六扣二){
      qianmenkou_jsons.push(BASEURL + '/model/mxf/1020_1073.json');
      qian_men_kou_id = xifu_map.双排四扣二;
    }
    qianmenkou_jsons.push(BASEURL + '/model/mxf/'+ qian_men_kou_id + '.json');
    qianmenkou_jsons.push(BASEURL + '/model/mxf/'+ qian_men_kou_id + '_1073.json');
    qianmenkou_jsons.push(BASEURL + '/model/mxf/'+ qian_men_kou_id + '_1074.json');
  } catch (e) {
    // console.error('=====get_qianmenkou_jsons=====', e)
  }

  return qianmenkou_jsons
}

function get_xiuzi_jsons(selectedCrafts){
  const xiuzi_jsons = [];
  try {
    const xiu_kou = selectedCrafts.find(item=>item.model_parent_id===xifu_map.袖扣) || xifu_default[xifu_map.袖扣]; // 汉邦默认 四粒门扣   真眼真衩钉扣（没找到这个定制项）
    const xiu_kou_dist = selectedCrafts.find(item=>item.model_parent_id===xifu_map.袖扣眼间距) || xifu_default[xifu_map.袖扣眼间距];
    const xiu_kou_direction = selectedCrafts.find(item=>item.model_parent_id===xifu_map.袖扣眼方向) || xifu_default[xifu_map.袖扣眼方向];
    const xiu_kou_id = xiu_kou.model_id;
    const xiu_kou_dist_id = xiu_kou_dist.model_id;
    const xiu_kou_direction_id = xiu_kou_direction.model_id;
    const pathParams = GetPathParams();
    console.log(pathParams);
    if (pathParams.navUrl === '/pages/completeCustom/index') {
      xiuzi_jsons.push(BASEURL + '/model/mxf/xiuzi_pleat.json');
    } else {
      xiuzi_jsons.push(BASEURL + '/model/mxf/xiuzi.json');
    }
    xiuzi_jsons.push(BASEURL + '/model/mxf/'+ xiu_kou_id + '_' + xiu_kou_dist_id + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mxf/'+ xiu_kou_id + '_' + xiu_kou_dist_id + '_' + xiu_kou_direction_id + '.json');
    if(xiu_kou_id === xifu_map.袖扣四粒扣){
      xiuzi_jsons.push(BASEURL + '/model/mxf/'+ xifu_map.袖扣三粒扣 + '_' + xiu_kou_dist_id + '.json');
    }
    if(xiu_kou_id === xifu_map.袖扣五粒扣){
      xiuzi_jsons.push(BASEURL + '/model/mxf/'+ xifu_map.袖扣三粒扣 + '_' + xiu_kou_dist_id + '.json');
      xiuzi_jsons.push(BASEURL + '/model/mxf/'+ xifu_map.袖扣四粒扣 + '_' + xiu_kou_dist_id + '.json');
    }
  } catch (e) {
    // console.error('=====get_xiuzi_jsons=====', e)
  }
  return xiuzi_jsons
}

// function get_chahua_yanse_fabrics(selectedCrafts){
//   let chahua_yanse_fabrics = [];
//   const chahua_yanse = selectedCrafts.find(item=>item.model_parent_id===xifu_map.插花眼色);
//   const chahua_yanse_id = chahua_yanse.model_id;
//   chahua_yanse_fabrics.push(BASEURL + '/model/mxf/' + chahua_yanse_id + '.jpg')
//   return chahua_yanse_fabrics
// }
// 测试
export const BASEURL_SDK = 'https://hcapi.shcaijiang.com/model';
export const BASEURL = 'https://hcxcx.shcaijiang.com';

export const BASEURL_CUSTOM = window.location.host === 'appletmodel.3vyd.com' ? 'https://appletapi.3vyd.com/' : 'https://wxqrcode.3vyd.com';
// 裁圣
// export const BASEURL_CUSTOM = 'https://wxqrcode.3vyd.com';

// export const BASEURL_CUSTOM = 'https://appletapi.3vyd.com';

// export const appid = 'wx069c4d7574501e36';
// export const product_id = '400003'

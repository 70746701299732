import { BASEURL } from '../Global.js'
import DefaultGongYi from './defaultGongYi';
import { get_select_crafts_work } from './common.js';

const MJ_DEF = DefaultGongYi.MMJ;

const majia_map = {
                '前门扣':         "4002",
                '领型':           "4003",
                '上口袋':         "4004",
                '下口袋':         "4005",
                '卡袋':           "4006",
                '后背':           "4007",
                '下摆':           "4008",
                'V型领':          "4016",
                '平驳无领座':     "4018",
                '戗驳无领座':     "4019",
                '青果无领座':     "4020",
                '无手巾袋':       "4021",
                '无卡袋':         "4031",
                '无腰带':         "4035",

                // 以下是自定义的
                '带扣':           "dk",
                '扣子':           "kz",
                '扣眼':           "ky",
                '衬衫':           "cs",
                '衬衫扣子':       "cs_kz",
                '衬衫扣眼':       "cs_ky",
};
export const get_Majia_Jsons = (craftWorks, indexArr) => {
  const appendix = [
    // BASEURL + '/model/mxf/ld.json',
    // BASEURL + '/model/mxf/cs.json',
  ]
  const dingzhiData = get_select_crafts_work(craftWorks, MJ_DEF);
  const lingxing_jsons = get_majia_jsons(dingzhiData);
  // const shangkoudai_jsons = get_shangkoudai_jsons(dingzhiData);
  // const xiakoudai_jsons = get_xiakoudai_jsons(dingzhiData);
  // const kadai_jsons = get_kadai_jsons(dingzhiData);
  // const houcha_jsons = get_houcha_jsons(dingzhiData);
  // const qianmenkou_jsons = get_qianmenkou_jsons(dingzhiData);
  // const xiuzi_jsons = get_xiuzi_jsons(dingzhiData);
  let all_list = appendix.concat(lingxing_jsons);
  for(let i=0; i < all_list.length; i++){
    if(all_list[i].includes('null')){
      all_list.splice(i, 1);
    }
  }
  return all_list
}

function get_majia_jsons(selectedCrafts){
  let majia_jsons = [];
  const qian_men_kou = selectedCrafts.find(item=>item.model_parent_id===majia_map.前门扣) || MJ_DEF[majia_map.前门扣];
  const ling_xing = selectedCrafts.find(item=>item.model_parent_id===majia_map.领型) || MJ_DEF[majia_map.领型];
  const shang_kou_dai = selectedCrafts.find(item=>item.model_parent_id===majia_map.上口袋) || MJ_DEF[majia_map.上口袋];
  const xia_dai = selectedCrafts.find(item=>item.model_parent_id===majia_map.下口袋) || MJ_DEF[majia_map.下口袋];
  const ka_dai = selectedCrafts.find(item=>item.model_parent_id===majia_map.卡袋) || MJ_DEF[majia_map.卡袋];
  const hou_bei = selectedCrafts.find(item=>item.model_parent_id===majia_map.后背) || MJ_DEF[majia_map.后背];
  const xia_bai = selectedCrafts.find(item=>item.model_parent_id===majia_map.下摆) || MJ_DEF[majia_map.下摆];
  let qian_men_kou_id = qian_men_kou.model_id;
  const ling_xing_id = ling_xing.model_id;
  const shang_kou_dai_id = shang_kou_dai.model_id;
  const xia_dai_id = xia_dai.model_id;
  const ka_dai_id = ka_dai.model_id;
  const hou_bei_id = hou_bei.model_id;
  const xia_bai_id = xia_bai.model_id;
  //前门扣 
  majia_jsons.push(BASEURL + '/model/mmj/' + qian_men_kou_id + '_' + majia_map.扣子 + '.json');
  majia_jsons.push(BASEURL + '/model/mmj/' + qian_men_kou_id + '_' + majia_map.扣眼 + '.json');
  //领型
  if(ling_xing_id===majia_map.平驳无领座||ling_xing_id===majia_map.戗驳无领座||ling_xing_id===majia_map.青果无领座){
    majia_jsons.push(BASEURL + '/model/mmj/' + qian_men_kou_id + '_' + ling_xing_id + '.json');
    majia_jsons.push(BASEURL + '/model/mmj/' + qian_men_kou_id + '_' + majia_map.V型领 + '_' + xia_bai_id + '.json');
  }else{
    majia_jsons.push(BASEURL + '/model/mmj/' + qian_men_kou_id + '_' + ling_xing_id + '_' + xia_bai_id + '.json');
  }
  //口袋
  if(shang_kou_dai_id!==majia_map.无手巾袋){
    majia_jsons.push(BASEURL + '/model/mmj/' + shang_kou_dai_id + '.json');
  }
  if(ka_dai_id!==majia_map.无卡袋){
    majia_jsons.push(BASEURL + '/model/mmj/' + ka_dai_id + '.json');
  }
  majia_jsons.push(BASEURL + '/model/mmj/' + xia_dai_id + '.json');
  //后背
  if(hou_bei_id!==majia_map.无腰带){
    majia_jsons.push(BASEURL + '/model/mmj/' + majia_map.无腰带 + '.json');
    majia_jsons.push(BASEURL + '/model/mmj/' + hou_bei_id + '_bp.json');
    majia_jsons.push(BASEURL + '/model/mmj/' + hou_bei_id + '_' + majia_map.带扣 + '.json');
  }else{
    majia_jsons.push(BASEURL + '/model/mmj/' + hou_bei_id + '.json');
  }
  //其他
  majia_jsons.push(BASEURL + '/model/mmj/' + majia_map.衬衫 + '.json');
  majia_jsons.push(BASEURL + '/model/mmj/' + majia_map.衬衫扣子 + '.json');
  majia_jsons.push(BASEURL + '/model/mmj/' + majia_map.衬衫扣眼 + '.json');
  return majia_jsons;
}

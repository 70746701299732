const buWeiGongYi = {
  MXF: {
    "1002": { // "前门扣"
      craft_name: "单排二粒扣",
      input_type: "IMG",
      show_order: 1,
      craftwork_id: "3022",
      model_flag: 1,
      model_parent_id: "1002",
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/mxz/3022.jpg",
      craft_code: "",
      model_id: "1015",
      craft_price: 0,
      parent_id: "3004",
      model_rel_id: ["1014","1014_1073","1014_1074","1015_1074"],
    },
    "1003": { // "领型"
      craft_name: "平驳领",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3015",
      model_flag: 1,
      model_parent_id: "1003",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/mxz/3015.jpg",
      craft_code: "",
      model_id: "1022",
      craft_price: 0,
      parent_id: "3003",
      model_rel_id: null,
    },
    "1004": { // "驳领宽"
      craft_name: "驳领宽6CM",
      input_type: "IMG",
      children: null,
      show_order: 2,
      craftwork_id: "3034",
      model_flag: 1,
      model_parent_id: "1004",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/mxz/3034.jpg",
      craft_code: "",
      model_id: "1026",
      craft_price: 0,
      parent_id: "3005",
      model_rel_id: null,
    },
    "1005": { // "插花眼形"
      craft_name: "机器真眼",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "259331512036573184",
      model_flag: null,
      model_parent_id: "1005",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/259717519953182720.png",
      craft_code: "111",
      model_id: "1075",
      craft_price: 0,
      parent_id: "3224",
      model_rel_id: null,
    },
    "1006": { // "插花眼色"
      craft_name: "顺色",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3233",
      model_flag: 1,
      model_parent_id: "1006",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com//253764226336768000.jpeg",
      craft_code: "",
      model_id: "1038",
      craft_price: 0,
      parent_id: "3232",
      model_rel_id: null,
    },
    "1007": { // "上口袋"
      craft_name: "2.3CM直手巾袋",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3053",
      model_flag: 1,
      model_parent_id: "1007",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/mxz/3053.jpg",
      craft_code: "",
      model_id: "1045",
      craft_price: 0,
      parent_id: "3007",
      model_rel_id: null,
    },
    "1008": { // "下口袋"
      craft_name: "平4CM正装袋",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3065",
      model_flag: 1,
      model_parent_id: "1008",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/mxz/3065.jpg",
      craft_code: "",
      model_id: "1051",
      craft_price: 0,
      parent_id: "3008",
      model_rel_id: null,
    },
    "1009": { // "卡袋"
      craft_name: "无卡袋",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3241",
      model_flag: null,
      model_parent_id: "1009",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/255392192678412288.png",
      craft_code: "",
      model_id: "1058",
      craft_price: 0,
      parent_id: "3240",
      model_rel_id: null,
    },
    "1010": { //"后衩"
      craft_name: "双开衩",
      input_type: "IMG",
      children: null,
      show_order: 3,
      craftwork_id: "3046",
      model_flag: 1,
      model_parent_id: "1010",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/mxz/3046.jpg",
      craft_code: "",
      model_id: "1064",
      craft_price: 0,
      parent_id: "3006",
      model_rel_id: null,
    },
    "1011": { // "袖扣"
      craft_name: "四粒扣",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3246",
      model_flag: 1,
      model_parent_id: "1011",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245922854372196352.jpg",
      craft_code: "",
      model_id: "1066",
      craft_price: 0,
      parent_id: "3245",
      model_rel_id: null,
    },
    "1012": { // "袖扣眼方向"
      craft_name: "平行扣眼",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3250",
      model_flag: 1,
      model_parent_id: "1012",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245923181771177984.jpg",
      craft_code: "",
      model_id: "1075",
      craft_price: 0,
      parent_id: "3249",
      model_rel_id: null,
    },
    "1013": {// "袖扣眼间距"
      craft_name: "平钉纽扣",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3253",
      model_flag: 1,
      model_parent_id: "1013",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245923581010198528.jpg",
      craft_code: "",
      model_id: "1071",
      craft_price: 0,
      parent_id: "3252",
      model_rel_id: null,
    }
  },
  MXK: {
    "3002": {
      craft_name: "无褶裤",
      input_type: "IMG",
      children: null,
      show_order: 0,
      craftwork_id: "3307",
      model_flag: null,
      model_parent_id: "3002",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/255393429700296704.png",
      craft_code: "",
      model_id: "3007",
      craft_price: 0,
      parent_id: "3302",
      model_rel_id: null,
    },
    "3003": {
      craft_name: "圆宝剑头",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3310",
      model_flag: null,
      model_parent_id: "3003",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/255393879388405760.png",
      craft_code: "",
      model_id: "3010",
      craft_price: 0,
      parent_id: "3303",
      model_rel_id: null,
    },
    "3004": {
      craft_name: "斜插袋",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3317",
      model_flag: 1,
      model_parent_id: "3004",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/252143544549064704.jpeg",
      craft_code: "",
      model_id: "3017",
      craft_price: 0,
      parent_id: "3304",
      model_rel_id: null,
    },
    "3005": {
      craft_name: "双开线钉扣",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3321",
      model_flag: null,
      model_parent_id: "3005",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/255395416772788224.png",
      craft_code: "",
      model_id: "3021",
      craft_price: 0,
      parent_id: "3305",
      model_rel_id: null,
    },
    "3006": {
      craft_name: "内折6cm",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3328",
      model_flag: null,
      model_parent_id: "3006",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/255397121560231936.png",
      craft_code: "",
      model_id: "3028",
      craft_price: 0,
      parent_id: "3306",
      model_rel_id: null,
    }
  },
  MMJ: {
    "4002": {
      craft_name: "单排五粒扣",
      input_type: "IMG",
      children: null,
      show_order: 3,
      craftwork_id: "3511",
      model_flag: null,
      model_parent_id: "4002",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/254992094605688832.jpg",
      craft_code: "",
      model_id: "4011",
      craft_price: 0,
      parent_id: "3502",
      model_rel_id: null,
    },
    "4003": {
      craft_name: "V型领",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3516",
      model_flag: null,
      model_parent_id: "4003",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/254992673306394624.jpg",
      craft_code: "",
      model_id: "4016",
      craft_price: 0,
      parent_id: "3503",
      model_rel_id: null,
    },
    "4004": {
      craft_name: "无手巾袋",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3521",
      model_flag: null,
      model_parent_id: "4004",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/254993147053031424.jpg",
      craft_code: "",
      model_id: "4021",
      craft_price: 0,
      parent_id: "3504",
      model_rel_id: null,
    },
    "4005": {
      craft_name: "平1.2cm一字袋",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3526",
      model_flag: null,
      model_parent_id: "4005",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/254993536548683776.jpg",
      craft_code: "",
      model_id: "4026",
      craft_price: 0,
      parent_id: "3505",
      model_rel_id: null,
    },
    "4006": {
      craft_name: "无卡袋",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3531",
      model_flag: null,
      model_parent_id: "4006",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/255404260693458944.png",
      craft_code: "",
      model_id: "4031",
      craft_price: 0,
      parent_id: "3506",
      model_rel_id: null,
    },
    "4007": {
      craft_name: "侧缝腰带",
      input_type: "IMG",
      children: null,
      show_order: 3,
      craftwork_id: "3537",
      model_flag: null,
      model_parent_id: "4007",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/254993988753375232.jpg",
      craft_code: "",
      model_id: "4037",
      craft_price: 0,
      parent_id: "3507",
      model_rel_id: null,
    },
    "4008": {
      craft_name: "八字尖角",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "3538",
      model_flag: null,
      model_parent_id: "4008",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/255404560456171520.png",
      craft_code: "",
      model_id: "4038",
      craft_price: 0,
      parent_id: "3508",
      model_rel_id: null,
    }
  },
  MCY: {
    "2002": {
      craft_name: "明门襟",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "242316396799803392",
      model_flag: 0,
      model_parent_id: "2002",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245866984716189696.jpg",
      craft_code: "",
      model_id: "2008",
      craft_price: 0,
      parent_id: "242303133382950912",
      model_rel_id: null,
    },
    "2003": {
      craft_name: "一字领",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "277848723411779584",
      model_flag: 1,
      model_parent_id: "2003",
      selected: true,
      craft_image: "https://bjhb.3vyd.com/craftwork/277848004663263232.png",
      craft_code: "HBNCSLXYZL",
      model_id: "2016",
      craft_price: 0,
      parent_id: "259676001800372224",
      model_rel_id: null,
    },
    "2004": {
      craft_name: "无胸袋",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245867609038340096",
      model_flag: 0,
      model_parent_id: "2004",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245867479786668032.jpg",
      craft_code: "",
      model_id: "2022",
      craft_price: 0,
      parent_id: "245849513791668224",
      model_rel_id: null,
    },
    "2005": {
      craft_name: "无褶收双省",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245865920617070592",
      model_flag: 0,
      model_parent_id: "2005",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245865858944024576.jpg",
      craft_code: "",
      model_id: "2026",
      craft_price: 0,
      parent_id: "245849380492492800",
      model_rel_id: null,
    },
    "2006": {
      craft_name: "圆摆",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245869765107728384",
      model_flag: 0,
      model_parent_id: "2006",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245869699479453696.jpg",
      craft_code: "",
      model_id: "2030",
      craft_price: 0,
      parent_id: "245849604602544128",
      model_rel_id: null,
    },
    "2007": {
      craft_name: "六角一眼两扣",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245872501157085184",
      model_flag: 1,
      model_parent_id: "2007",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245872343270899712.jpg",
      craft_code: "",
      model_id: "2032",
      craft_price: 0,
      parent_id: "245849822081400832",
      model_rel_id: null,
    }
  },
  MDY: {
    "6002": {
      craft_name: "单排三粒扣",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245911069392977920",
      model_flag: 1,
      model_parent_id: "6002",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245910987822153728.jpg",
      craft_code: "",
      model_id: "6015",
      craft_price: 0,
      parent_id: "243702891762761728",
      model_rel_id: null,
    },
    "6003": {
      craft_name: "平驳领",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245910704631136256",
      model_flag: 1,
      model_parent_id: "6003",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245910603103813632.jpg",
      craft_code: "",
      model_id: "6023",
      craft_price: 0,
      parent_id: "243702678960553984",
      model_rel_id: null,
    },
    "6004": {
      craft_name: "驳领宽7cm",
      input_type: "IMG",
      children: null,
      show_order: 2,
      craftwork_id: "245912259098918912",
      model_flag: 1,
      model_parent_id: "6004",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245912056551784448.jpg",
      craft_code: "",
      model_id: "6027",
      craft_price: 0,
      parent_id: "243733092500586496",
      model_rel_id: null,
    },
    "6005": {
      craft_name: "未来之星",
      input_type: "IMG",
      children: null,
      show_order: 4,
      craftwork_id: "253871243533565952",
      model_flag: null,
      model_parent_id: "6005",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/252493432520523776.png",
      craft_code: "111",
      model_id: "6030",
      craft_price: 0,
      parent_id: "253851197922951168",
      model_rel_id: null,
    },
    "6006": {
      craft_name: "顺色",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "253872197372821504",
      model_flag: 1,
      model_parent_id: "6006",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com//253764226336768000.jpeg",
      craft_code: "",
      model_id: "6037",
      craft_price: 0,
      parent_id: "253851370615029760",
      model_rel_id: null,
    },
    "6007": {
      craft_name: "直手巾袋袋宽2.5CM",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245920179819724800",
      model_flag: 1,
      model_parent_id: "6007",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245920125688037376.jpg",
      craft_code: "",
      model_id: "6044",
      craft_price: 0,
      parent_id: "245847615059607552",
      model_rel_id: null,
    },
    "6008": {
      craft_name: "平正装袋袋宽6CM",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245920310833004544",
      model_flag: 1,
      model_parent_id: "6008",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245920243959021568.jpg",
      craft_code: "",
      model_id: "6049",
      craft_price: 0,
      parent_id: "245847753534554112",
      model_rel_id: null,
    },
    "6010": {
      craft_name: "不开衩",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245912767146573824",
      model_flag: 1,
      model_parent_id: "6010",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245912557628506112.png",
      craft_code: "",
      model_id: "6058",
      craft_price: 0,
      parent_id: "245846996848558080",
      model_rel_id: null,
    },
    "6011": {
      craft_name: "直角袖口",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245921101224427520",
      model_flag: 1,
      model_parent_id: "6011",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245921028235149312.jpg",
      craft_code: "",
      model_id: "6060",
      craft_price: 0,
      parent_id: "245847857804951552",
      model_rel_id: null,
    },
    "6012": {
      craft_name: "四粒扣",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245922948588847104",
      model_flag: 1,
      model_parent_id: "6012",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245922854372196352.jpg",
      craft_code: "",
      model_id: "6064",
      craft_price: 0,
      parent_id: "245922628722835456",
      model_rel_id: null,
    },
    "6013": {
      craft_name: "平行扣眼",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245923233629552640",
      model_flag: 1,
      model_parent_id: "6013",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245923181771177984.jpg",
      craft_code: "",
      model_id: "6067",
      craft_price: 0,
      parent_id: "245847960519262208",
      model_rel_id: null,
    },
    "6014": {
      craft_name: "平钉纽扣",
      input_type: "IMG",
      children: null,
      show_order: 1,
      craftwork_id: "245923707388772352",
      model_flag: 1,
      model_parent_id: "6014",
      selected: true,
      craft_image: "https://hcxcx.shcaijiang.com/craftwork/245923581010198528.jpg",
      craft_code: "",
      model_id: "6069",
      craft_price: 0,
      parent_id: "245848242942722048",
      model_rel_id: null,
    }
  },
  MJK: {

  },
  MXXK: {
    "7022": {
      model_id: "7220"
    },
    "7023": {
      model_id: "7230"
    },
    "7025": {
      model_id: "7250"
    },
    "7026": {
      model_id: "7260"
    },
    "7027": {
      model_id: "7270"
    },
    "7028": {
      model_id: "7280"
    },
    "7029": {
      model_id: "7290"
    },
    "7109": {
      model_id: "7895"
    },
    "7112": {
      model_id: "7931"
    },
    "7110": {
      model_id: "7910"
    },
    "7111": {
      model_id: "7920"
    },
  }
}

export default buWeiGongYi;
import { BASEURL } from '../Global.js';
import DefaultGongYi from './defaultGongYi';
import { get_select_crafts_work } from './common.js';

const CY_DEF = DefaultGongYi.MCY;

const chenshan_map = {
                '门襟':         "2002",
                '领型':         "2003",
                '胸袋':         "2004",
                '后背款式':     "2005",
                '下摆':         "2006",
                '袖型':         "2007",
                '明门襟':       "2008",
                '软门襟':       "2009",
                '暗门襟':       "2010",
                '多褶明门襟':   "2011",
                '正装尖领':     "2013",
                '尖领明扣':    "2019",
                '高领两扣':     "2021",
                '无胸袋':       "2022",
                '六角一眼两扣': "2032",
                '圆角一眼两扣': "2033",
                '弧形单扣':     "2034",
                '六角两眼两扣': "2035",
                '圆角两眼两扣': "2036",
                '直角翻折法袖': "2037",
                '六角翻折法袖': "2038",
                '圆角翻折法袖': "2039",
                '绅士袖':       "2040",
                '翻折梯形袖':   "2049",
                '直角一眼两扣': "2050",
                '斜角一眼两扣': "2051",
                '经典硬领':    "2052",
                // 以下是自定义的
                '扣子':         "2041",
                '正常袖':       "2042",
                '法袖':         "2043",
                '弧形袖':       "2044",
                '袖旗':         "2045",
                '一眼两扣':     "2046",
                '两眼两扣':     "2047",
                '扣眼':         "2048",
                '后片':         "hp",
};
export const get_Chenshan_Jsons = (craftWorks, indexArr) => {
  const appendix = [
    BASEURL + '/model/mcy/hp.json',
  ]
  const dingzhiData = get_select_crafts_work(craftWorks, CY_DEF);
  const lingxing_jsons = get_lingxing_jsons(dingzhiData);
  const koudai_jsons = get_koudai_jsons(dingzhiData);
  const houbei_jsons = get_houbei_jsons(dingzhiData);
  const menjin_jsons = get_menjin_jsons(dingzhiData);
  const xiuzi_jsons = get_xiuzi_jsons(dingzhiData);
  let all_list = appendix.concat(lingxing_jsons, menjin_jsons, koudai_jsons, houbei_jsons, xiuzi_jsons);
  for(let i=0; i < all_list.length; i++){
    if(all_list[i].includes('null')){
      all_list.splice(i, 1);
    }
  }
  return all_list
}

function get_lingxing_jsons(selectedCrafts){
  let lingxing_jsons = [];
  const ling_xing = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.领型) || CY_DEF[chenshan_map.领型];
  const ling_xing_id = ling_xing.model_id;
  lingxing_jsons.push(BASEURL + '/model/mcy/' + ling_xing_id + '.json');
  lingxing_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.领型 + '_' + chenshan_map.扣子 +  '.json');
  lingxing_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.领型 +'_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
  if(ling_xing_id===chenshan_map.尖领明扣){
    lingxing_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.正装尖领 + '.json');
    lingxing_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.尖领明扣 + '.json');
    lingxing_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.尖领明扣 + '_' + chenshan_map.扣眼 +'.json');
  }
  if(ling_xing_id===chenshan_map.高领两扣){
    lingxing_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.高领两扣 + '.json');
    lingxing_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.高领两扣 + '_' + chenshan_map.扣子 +  '.json');
    lingxing_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.高领两扣 +'_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
  } 
  return lingxing_jsons;
}

function get_koudai_jsons(selectedCrafts){
  let koudai_jsons = [];
  const men_jin = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.门襟) || CY_DEF[chenshan_map.门襟];
  const kou_dai = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.胸袋) || CY_DEF[chenshan_map.胸袋];
  let men_jin_id = men_jin.model_id;
  const kou_dai_id = kou_dai.model_id;
  if(men_jin_id!==chenshan_map.多褶明门襟&&kou_dai_id!==chenshan_map.无胸袋){
    koudai_jsons.push(BASEURL + '/model/mcy/' + kou_dai_id + '.json')
  }
  return koudai_jsons
}

function get_houbei_jsons(selectedCrafts){
  let houbei_jsons = [];
  const hou_bei = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.后背款式) || CY_DEF[chenshan_map.后背款式];
  const xia_bai = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.下摆) || CY_DEF[chenshan_map.下摆];
  let hou_bei_id = hou_bei.model_id;
  let xia_bai_id = xia_bai.model_id;

  houbei_jsons.push(BASEURL + '/model/mcy/'+ hou_bei_id + '_' + xia_bai_id + '.json');
  return houbei_jsons
}

function get_menjin_jsons(selectedCrafts){
  let menjin_jsons = [];
  const qianmenjin = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.门襟) || CY_DEF[chenshan_map.门襟];
  const xiabai =selectedCrafts.find(item=>item.model_parent_id===chenshan_map.下摆) || CY_DEF[chenshan_map.下摆];
  let men_jin_id = qianmenjin.model_id;
  let xia_bai_id = xiabai.model_id;
  if(men_jin_id===chenshan_map.明门襟){
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.明门襟 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '_' +chenshan_map.扣眼 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
  }
  if(men_jin_id===chenshan_map.软门襟){
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '_' +chenshan_map.扣眼 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
  }
  if(men_jin_id===chenshan_map.暗门襟){
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.明门襟 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
  }
  if(men_jin_id===chenshan_map.多褶明门襟){
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.明门襟 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.暗门襟 + '_' +chenshan_map.扣眼 + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.多褶明门襟 + '_' +xia_bai_id + '.json');
    menjin_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.软门襟 + '_' +xia_bai_id + '.json');
  }
  return menjin_jsons
}

function get_xiuzi_jsons(selectedCrafts){
  let xiuzi_jsons = [];
  let zheng_chang_xiu_jsons = [];
  let fa_xiu_jsons = [];
  const xiu_kou = selectedCrafts.find(item=>item.model_parent_id===chenshan_map.袖型) || CY_DEF[chenshan_map.袖型];
  const xiu_kou_id = xiu_kou.model_id;
  zheng_chang_xiu_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.正常袖 + '.json');
  zheng_chang_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '.json');
  zheng_chang_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '_' + chenshan_map.扣子 + '.json');
  zheng_chang_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '_' + chenshan_map.扣子 + chenshan_map.扣眼 + '.json');  
  
  fa_xiu_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.法袖 + '.json')
  fa_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '.json');
  fa_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '_' + chenshan_map.扣子 + '.json');
  fa_xiu_jsons.push(BASEURL + '/model/mcy/'+ chenshan_map.正常袖 + '_' + chenshan_map.袖旗 + '_' + chenshan_map.扣子 + chenshan_map.扣眼 + '.json');
  
  if(xiu_kou_id === chenshan_map.弧形单扣){
    xiuzi_jsons = zheng_chang_xiu_jsons
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.弧形袖 + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.弧形单扣 + '_' + chenshan_map.扣子 + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.一眼两扣 + '_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
  }
  if(xiu_kou_id === chenshan_map.绅士袖){
    xiuzi_jsons = zheng_chang_xiu_jsons
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + xiu_kou_id + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.两眼两扣 + '_' + chenshan_map.扣子 + '.json');
  }
  if(xiu_kou_id === chenshan_map.六角一眼两扣||xiu_kou_id === chenshan_map.圆角一眼两扣||xiu_kou_id === chenshan_map.直角一眼两扣||xiu_kou_id === chenshan_map.斜角一眼两扣){
    xiuzi_jsons = zheng_chang_xiu_jsons
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + xiu_kou_id + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.一眼两扣 + '_' + chenshan_map.扣子 + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.一眼两扣 + '_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
  }
  if(xiu_kou_id === chenshan_map.六角两眼两扣||xiu_kou_id === chenshan_map.翻折梯形袖||xiu_kou_id === chenshan_map.圆角两眼两扣){
    xiuzi_jsons = zheng_chang_xiu_jsons
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + xiu_kou_id + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.两眼两扣 + '_' + chenshan_map.扣子 + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.两眼两扣 + '_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
  }
  if(xiu_kou_id === chenshan_map.直角翻折法袖||xiu_kou_id === chenshan_map.圆角翻折法袖||xiu_kou_id === chenshan_map.六角翻折法袖){
    xiuzi_jsons = fa_xiu_jsons
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + xiu_kou_id + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.六角翻折法袖 + '_' + chenshan_map.扣子 + '.json');
    xiuzi_jsons.push(BASEURL + '/model/mcy/' + chenshan_map.六角翻折法袖 + '_' + chenshan_map.扣子 + '_' + chenshan_map.扣眼 + '.json');
  }
  return xiuzi_jsons
}

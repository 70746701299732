import React from 'react'
import { FixedSizeList as List } from 'react-window';
import { init, GetPathParams } from './util'
import { BASEURL, BASEURL_SDK } from './Global';
import wx from 'weixin-js-sdk';
import { getFabricList } from './api/getFabricList';
import { getDingzhiInfo } from './api/getCraftsInfo';
import { get_Xifu_Jsons } from './api/getXifuJsons';
import { get_Chenshan_Jsons } from './api/getChenshanJsons';
import { get_Xiku_Jsons } from './api/getXikuJsons';
import { get_Dayi_Jsons } from './api/getDayiJsons';
import { get_Jiake_Jsons } from './api/getJiakeJsons';
import { get_Majia_Jsons } from './api/getMajiaJsons';
import { get_XiuXianKu_Jsons } from './api/getXiuXianKuJsons';
import DefaultGongYi from './api/defaultGongYi';
import GongYiRulesMap from './api/gongYiRules';
import {getBlobWithUrl,dataURLtoFile,upLoadFile,csUpLoadFile} from './util'
import './App.css';
import { Spin, Icon } from 'antd';
import { get_select_crafts_work } from './api/common';
import Selector from './components/selector';
import { get, post, upLoad} from './api';

// const fabricList = [
//   '31399-105',
//   '21312-7107',
// ]
window.onunload = function(){
  wx.miniProgram.navigateBack({})
}

const urlParams = GetPathParams();

export const fromHb = urlParams.navUrl === '/pages/completeCustom/index';

const whiteListMap = fromHb ? {
  MXF: ['1010', '1011', '1009'], // ["后开叉", "袖口", "卡袋", "袖叉"(没有)]
} : { };

const GongYiRules = GongYiRulesMap[fromHb ? 'hb' : 'cs'][urlParams.category_code];
const nowRulesMap = {};

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

//分离第一步定制信息
function getFirstStepInfo(data) {
  const firstData = [];
  const whiteList = whiteListMap[urlParams.category_code] || [];
  if(data.craftworks && data.craftworks.children){
    data.craftworks.children.forEach((item) => {
        if(item.model_flag === 1 && whiteList.indexOf(item.model_id) === -1){
          firstData.push(item);
        }
    }); 
    
  }
  return firstData;
}
 //分离第二步定制信息
 function getSecStepInfo(data) {
  let secondData = [];
  if(data.craftworks&&data.craftworks.children){
    data.craftworks.children.map((item)=>{
        if(item.model_flag!=="1"){
          secondData.push(item);
        }
        // return 0
    }); 
  }
  return secondData;
}
//初始化默认工艺选项
function initFirstOptions(firstData) {
  let array = [];
  for(let i=0; i<firstData.length; i++){
    if(firstData[i].children&&firstData[i].children.length>0){
        let crafts = firstData[i].children;
        for(let j=0; j<crafts.length; j++){
            if(crafts[j].selected){
              array[i] = j;
                break
            }else{
              array[i] = 0;
            }
        }
    }
  }
  return array;
}

class App extends React.PureComponent {

  replaceJSONs = [
    'https://hcxcx.shcaijiang.com/model/replace.json',
  ];

  scene = [
    'https://hcxcx.shcaijiang.com/model/mobile/scene.json',
    'https://hcxcx.shcaijiang.com/model/mdy/scene.json',
    'https://hcxcx.shcaijiang.com/model/mcy/scene.json',
  ];

  appendixs = [
    BASEURL+'/model1/microcust/cs2.json',
    BASEURL+'/model/microcust/ld1.json',
    BASEURL+'/model1/microcust/pingkou_32.json',
    BASEURL+'/model1/microcust/4k2_kz2.json',
  ];

  pps = {
    xifu: 8,
    'demo/chenyi': 6,
    mj_chenyi: 4,
    lingDai: 20,
    kuzi: 10,
    majia: 10,
    xxku: 1
  };

  sdk = null;

  nexting = false;

  models = [];

  interal = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeIndex1: -1,
      fabricIndex: 0,
      activeIndex2: [0,0,0],
      isVip: false,
      firstCraftworks: [],
      fabricsInfo: [],
      allPrice: 0,
      addPrice: [],
      price: 0,
      sdkInited: false, //是否初始化，第一次加载模型
      initPrecent: 0,
      fabricPrices: [],
      fabricBrand: [],
      fabricSearch: '',
      priceSeach: '',
      brandSearch: ''
    };
  }

  componentWillMount() {
    this.setUp();
  }

  render() {
    const { loading, activeIndex1, firstCraftworks, fabricsInfo, activeIndex2, price, sdkInited, initPrecent, fabricPrices, fabricBrand } = this.state;
    return (
      <div className="appContainer">
        <div className="canvas" id="canvas_container" style={{ margin: 'auto', height: '56vh', width: '100vw', position: 'relative' }}>
          {  
            loading &&
            <div className="loadContainer">
              {!sdkInited ? (
                             <div className="sdkLoading">
                               <img src={require('./images/LOADING.png')} className="sdkLoadingImg" alt="" />
                               <span className="sdkLoadingTxt">{`模型加载中...(${initPrecent}%)`}</span>
                             </div>
                           )
                         : <Spin indicator={antIcon} size="large" />}
            </div>
          }
        </div>
        <div className="boxContainer">
        <div className="tabParent">
          <div className="craftTabs" onClick={() => this.setState({ activeIndex1: -1 })}>
            <div className={ activeIndex1===-1?"tabItemSel":"tabItem" }>面料</div>
          </div>
            {
              firstCraftworks.map((item, index)=> {
                return (
                  <div className="craftTabs" key={index}>
                    <div 
                      className={activeIndex1===index?"tabItemSel":"tabItem" + (!item.disabled ? '' : ' tab-item-disable')}
                      onClick={() => !item.disabled && this.selectCraft(index)}
                    >
                      {item.craft_name}
                    </div>
                  </div>
                );
              })
            }
        </div>
        {activeIndex1===-1 && (
          <div>
            <Selector prices={fabricPrices} brands={fabricBrand} onInput={this.onFabricInput} fabricSearch={this.fabricSearch} />
            <div>
              <List
                key="fabricList"
                height='20vh'
                itemCount={fabricsInfo.length}
                itemSize={90}
                layout="horizontal"
                itemData={[]} // 每次给个新值可以刷新item 显示选中
                width={window.screen.width}
              >
                {this.getColumn}
              </List>
            </div>
          </div>
        )}
        {
          activeIndex1>=0&&
          <div className="tabChildren">
            {
              firstCraftworks[activeIndex1].children.map((item, index) => {
                const selectedItem = index === activeIndex2[activeIndex1];
                const disabled = item.disabled
                return (
                  <div 
                    className= {selectedItem ? "craftTabs3Sel" : "craftTabs3"} 
                    onClick={()=> !selectedItem && !disabled && this.setActiveIndex2(firstCraftworks[activeIndex1], index)} 
                    key={index}
                  >
                    <div className="imageContainer2">
                      <img className="craftImage" alt="isImage" key={`${item.model_parent_id}/${item.model_id}`} src={item.craft_image} />
                      {disabled && <img className="disabled-icon" alt="" src={require('./images/disabled-icon.svg')} />}
                    </div>
                    <img className={selectedItem ? 'cornerImg' : 'cornerImg2'} alt="isImage" src={require('./images/circleChoose.svg')} />
                    <div className="tabItem2">{item.craft_name}</div>
                  </div>
                )      
              })
            }
          </div>
        }
      </div>
        <div className="bottomBox">
          <div className="price">￥{price}</div>
          <div className="nextBtn clickLayout" onClick={() => this.toNext()}>
            <div>下一步</div>
          </div>
        </div>
      </div>
    );
  }

  getColumn = ({ index, style }) => {
    const { fabricsInfo, fabricIndex } = this.state;
    // const columnItems = [fabricsInfo[2 * index]];
    // (2 * index + 1) < fabricsInfo.length && columnItems.push(fabricsInfo[2 * index + 1]);
    const item = fabricsInfo[index];
    return (
      <div style={style} key={item.fabric_code}>
        <div className= {index===fabricIndex ? 'craftTabs2Sel' :' craftTabs2'} onClick={()=>{this.changeFabricIndex(index)}}>
          <img className="fabricImage" alt="isImage" src={item.fabric_url + '?imageView2/2/w/200'} />
          <img className={index===fabricIndex ? "cornerImg3" :' cornerImg3Sel'} alt="isImage" src={require('./images/circleChoose.svg')} />
          <div className="tabItem2">{item.fabric_code}</div>
          <div className="tabItem2">{`￥${item.fabric_price}`}</div>
        </div>
      </div>
    )
  }

  setUp = async () => {
    this.setState({ loading: true }, () => (this.interal = setInterval(() => {
      const { initPrecent } = this.state;
      if (initPrecent >= 99) {
        this.stopInteral();
      } else {
        this.setState({ initPrecent: initPrecent + 1 });
      }
    }, 30)));
    await Promise.all([this.loadData(), this.initSDK(), this.loadFabricPrices(), this.loadFabricBrand()]);
    this.effect();
  }

  loadData = async() => {
    this.setState({ loading: true });
    const params = urlParams;
    console.log("路径参数： ", params);
    const { activeIndex2, fabricIndex, isVip } = params;
    if(params.activeIndex2&&params.activeIndex2.length>0) {
      this.setState({ activeIndex2, fabricIndex, isVip  });
    }
    console.log("Vip信息：", isVip);
    let dingzhiData = [];
    dingzhiData = await getDingzhiInfo(params);

    console.log("定制信息赋值：", dingzhiData);
    //计算定制初始价格

    const firstDataRes = getFirstStepInfo(dingzhiData);
    const secondCraftworks = getSecStepInfo(dingzhiData);
    const indexArr = initFirstOptions(firstDataRes);
    
    console.log("activeIndex2: ",indexArr);
    //计算定制初始价格
    let fabricPrice = 0;
    if(dingzhiData.fabrics && dingzhiData.fabrics[fabricIndex]){
      fabricPrice = isVip?dingzhiData.fabrics[fabricIndex].fabric_vip_price:dingzhiData.fabrics[fabricIndex].fabric_price;
    }
    let totalPrice = fabricPrice;
    //第一步价格相加
    for(let i=0; i<firstDataRes.length; i++){
      totalPrice += firstDataRes[i].children[indexArr[i]].craft_price;
    }
    //第二步价格相加
    let tempArr3 = [];
    for(let i=0; i<secondCraftworks.length; i++){
      if(secondCraftworks[i].children&&secondCraftworks[i].children.length>0){
          let crafts = secondCraftworks[i].children;
          for(let j=0; j<crafts.length; j++){
              if(crafts[j].selected){
                tempArr3[i] = j;
                  break
              }else{
                tempArr3[i] = 0;
              }
          }
      }
    }
    for(let i=0; i<tempArr3.length; i++){
        totalPrice += secondCraftworks[i].children[tempArr3[i]].craft_price;
    }
    this.setState({ 
      firstCraftworks: firstDataRes, 
      activeIndex2: indexArr, 
      price: totalPrice.toFixed(2), 
      fabricsInfo: dingzhiData.fabrics || [] 
    });
  }

  loadFabricPrices = async () => {
    try {
      const { category_id } = urlParams;
      const { code , data } = await get(`/api/public/custom/fabric/price-range/${category_id}`);
      if (code === 0) {
        this.setState({ fabricPrices: data });
      }
    } catch (e) {
      console.log(e);
    }
  }

  loadFabricBrand = async () => {
    try {
      const { code , data } = await get('/api/public/custom/fabric/brand');
      if (code === 0) {
        this.setState({ fabricBrand: data });
      }
    } catch (e) {
      console.log(e);
    }
  }

  fabricSearch = async (params) => {
    try {
      const { category_id } = urlParams;
      const { fabricSearch, priceSeach, brandSearch } = { ...this.state, ...params };
      const { price_min = '', price_max = '' } = JSON.parse(priceSeach || '{}');
      const payload = { category_id, page:0, size: 10000 };
      price_min && (payload.price_min = price_min);
      price_max && (payload.price_max = price_max);
      fabricSearch && (payload.fabric_code = fabricSearch);
      brandSearch && (payload.fabric_brand = brandSearch);
      this.setState(params);
      const { code, data } = await post(`/api/public/custom/fabric/search/${category_id}`, payload);
      if (code === 0) {
        this.setState({ fabricsInfo: data },this.effect);
      }
    } catch (e) {
      console.log(e);
    }
  }
  
  initSDK = async () => {
    var browser = (function () {
      var u = navigator.userAgent, app = navigator.appVersion;
      return { //移动终端浏览器版本信息
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
      iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1 //是否iPad};
      }
      })();
    const r = browser.ios?true:1;
    this.setState({ loading: true });
    const sdk = await init();
    //加载场景
    const params = await GetPathParams();
    if (params.category_code ==='MDY') {
      await sdk.init('canvas_container', this.scene[1], 2);
      sdk.setScale(1, [1, 5]);
    } else if (params.category_code ==='MCY'){
      await sdk.init('canvas_container', this.scene[2], 2);
      sdk.setScale(1, [1, 5]);
    } else {
      await sdk.init('canvas_container', this.scene[0], 2);
      sdk.setScale(1.8, [1, 5]);
    }
    // 加载材质
    await sdk.loadDynamicMaterial(this.replaceJSONs[0]);
    this.sdk = sdk;
    // this.setState({ loading: false, sdkInited: true });
  }


  effect = async () => {
    const { sdk } = this;
    if (!sdk) return
    this.setState({ loading: true });
    let fabricPrice = 0;
    let totalPrice = 0;
    const { fabricsInfo, fabricIndex, isVip, firstCraftworks, activeIndex2 } = this.state;
    if(fabricsInfo[fabricIndex]){
      fabricPrice = isVip?fabricsInfo[fabricIndex].fabric_vip_price:fabricsInfo[fabricIndex].fabric_price;
    }
    totalPrice = fabricPrice;
    //第一步价格相加
 
    // for(let i=0; i<activeIndex2.length; i++){
    //   totalPrice += firstCraftworks[i].children[activeIndex2[i]].craft_price;
    // }
    // 面料价格加上工艺价格
    for(let i=0; i<activeIndex2.length; i++){
      totalPrice += firstCraftworks[i].children[activeIndex2[i]].craft_price;
    }
    this.setState({ price: totalPrice.toFixed(2) });
    let model_json_list = [];
    switch(urlParams.category_code){
      case 'MXF': //西服
          model_json_list = get_Xifu_Jsons(firstCraftworks);
          break
      case 'MCY': //衬衣
          model_json_list = get_Chenshan_Jsons(firstCraftworks);
          break
      case 'MXK': //西裤
          model_json_list = get_Xiku_Jsons(firstCraftworks);
          break
      case 'MDY': //外套
          model_json_list = get_Dayi_Jsons(firstCraftworks);
          break
      case 'MJK': //夹克
          model_json_list = get_Jiake_Jsons(firstCraftworks);
          break
      case 'MMJ': //马甲
          model_json_list = get_Majia_Jsons(firstCraftworks);
          break
      case 'MXXK': //男士休闲裤
          model_json_list = get_XiuXianKu_Jsons(firstCraftworks);
          break
      default:
          model_json_list = [];
    };
    // const model_json_list = get_Xifu_Jsons(firstCraftworks);
    console.log("过滤工艺：", model_json_list);
    
    // 加载模型
    const baseModels = await sdk.loadModels(model_json_list);
    this.models = baseModels;
    // await sdk.updateBaseTexture('http://39.98.137.244:7777/demo/fabric/JNS496187.jpg', 4)
    const chahuayan = ['1031', '1032', '1033', '1034', '1035', '1036', '1037', '1075', '1077', '1078', '1079',
                      '6030', '6031', '6032', '6033', '6034', '6035', '6036', '6071', '6072', '6073', '6074', '6075','6076','6077']

    const selectedCrafts = get_select_crafts_work(firstCraftworks, DefaultGongYi[urlParams.category_code]);
  
    const cha_hua_yan_se = selectedCrafts.find(item=>(item.model_parent_id==='1006'||item.model_parent_id==='6006'));
    //绘制模型
    baseModels.forEach(m =>{
      const { id: objId, key: objPath } = m
      sdk.showObj(objId, objPath)

      let baseTextureUrl = '';
      if(fabricsInfo.length>0&&fabricsInfo[fabricIndex]){
        baseTextureUrl = fabricsInfo[fabricIndex].fabric_url;

      }
      for(let i=0; i<chahuayan.length; i++){
        if(objPath.includes(chahuayan[i])){
          if(cha_hua_yan_se.craft_name==='顺色'){
            if (fabricsInfo[fabricIndex]) {
              baseTextureUrl = fabricsInfo[fabricIndex].fabric_url;
            }
           
          }else{
            baseTextureUrl = cha_hua_yan_se.craft_image;
          }
        }
      }
      if (baseTextureUrl) {
        // 附件不变更材质
        if (this.appendixs.includes(objPath)) {
          sdk.showObj(objId, objPath)
          return
        }
        // 更新材质
        if (objPath.includes('cust/cs')) {
          sdk.showObj(objId, objPath)
        } else {
          let repeatTimes = 10;
          if(urlParams.category_code==='MXXK') {
            repeatTimes = this.pps.xxku
          }
          sdk.updateBaseTexture(baseTextureUrl, repeatTimes)
            .then(() => {
              sdk.freshFabric(objId, objPath)
              sdk.showObj(objId, objPath)
            })
        }
      }
    })
    console.log("acitveIndex2: ",activeIndex2);
    //设置附件默认材质
    baseModels.forEach(m => {
      let baseTextureUrl = ''
      const {
        id: objId,
        key: objPath
      } = m
      if (objPath.includes('ld1')) {
        baseTextureUrl = 'http://rc3dengine.3vyd.com/fabric/xifu/HR11926.jpg'
      } else if (objPath.includes('cust/cs')) {
        baseTextureUrl = 'https://hcxcx.shcaijiang.com/model1/microcust/chenshan.jpg';
      } else if (objPath.includes('kouDaiJin')) {
        baseTextureUrl = 'http://rc3dengine.3vyd.com/fabric/xifu/HR14230.jpg'
      } else {
        return
      }
      sdk.updateBaseTexture(baseTextureUrl, 4)
        .then(() => {
          sdk.freshFabric(objId, objPath)
        })
    })
    // sdk.resetCamera();
    //加载场景
    const params = await GetPathParams();
    if (params.category_code ==='MDY') {
      sdk.resetCamera(1, [1, 5]);
    } else if (params.category_code ==='MCY'){
      sdk.resetCamera(1, [1, 5]);
    } else {
      sdk.resetCamera(1.8, [1, 5]);
    }
    // sdk.resetCamera();
    if (!this.state.sdkInited) {
      this.stopInteral();
      this.setState({ initPrecent: 100, loading: false, sdkInited: true }, () => setTimeout(() => this.setState({ loading: false, sdkInited: true }), 500));
    } else {
      this.setState({ loading: false });
    }
  }
  // 定制工艺
  setActiveIndex2 = (craftworks, index1) => {
    const { firstCraftworks, activeIndex1, activeIndex2 } = this.state;
    craftworks.children.forEach(item=> (item.selected = false));
    craftworks.children[index1].selected = true;
    let tempCraftworks = JSON.parse(JSON.stringify(firstCraftworks));
    tempCraftworks[activeIndex1] = craftworks;

    const array = [...activeIndex2];
    array[activeIndex1] = index1;
    const newPrice = craftworks.children[index1].craft_price;
    this.takeConflict(craftworks,activeIndex1, index1, tempCraftworks, array);
    
    this.setState({ firstCraftworks: tempCraftworks, activeIndex2: array,allPrice : newPrice}, this.effect);
  }

  changeFabricIndex = (index) => {
    const { fabricIndex, fabricsInfo,  } = this.state;
    if(index === fabricIndex) {
      return
    } else {
      // this.setState({ fabricIndex: index, price: fabricsInfo[index].fabric_price.toFixed(2) });
      this.setState({ fabricIndex: index,},this.effect);
      const textureUrl = fabricsInfo[index].fabric_url;
      this.models.forEach(model => {
        this.changeMaterial(model, textureUrl)
      })
    }
  }

  changeMaterial = async (modelInfo, textureUrl) => {
    const {
      id,
      key
    } = modelInfo
    // 更新材质
    let repeatTimes = this.pps.xifu
    console.log("当前品类code: ", urlParams.category_code)
    if(urlParams.category_code==='MXXK') {
      repeatTimes = this.pps.xxku
    }
    await this.sdk.updateBaseTexture(textureUrl, repeatTimes)
    // 应用材质
    // 附件不变更材质
    if (this.appendixs.includes(key)) {
      return
    }
    
    this.sdk.freshFabric(id, key)
  }
  //选择定制部位
  selectCraft = (index) => {
    const { firstCraftworks, activeIndex2 } = this.state;
    this.setState({ activeIndex1: index });
    const craftworks = firstCraftworks[index];

    const firstLevelRules = GongYiRules[craftworks.model_id];
    if (firstLevelRules) {
      let tempCraftworks = JSON.parse(JSON.stringify(firstCraftworks));
      const array = [...activeIndex2];
      const index1 = array[index];
      this.takeConflict(craftworks, index, index1, tempCraftworks, array);
      let newindex = 0;

      this.setState({ activeIndex2: array, firstCraftworks: tempCraftworks }, this.effect);
    }
  }

  takeConflict = (craftworks, craftIndex, index1, tempCraftworks, array) => {
    const firstLevelRules = GongYiRules[craftworks.model_id];
    const { activeIndex2 } = this.state;
    if (firstLevelRules) {
      const originCraft = craftworks.children[activeIndex2[craftIndex]];
      const newCraft = craftworks.children[index1];
      delete nowRulesMap[`${originCraft.model_parent_id}/${originCraft.model_id}`];
      firstLevelRules[newCraft.model_id] && (nowRulesMap[`${newCraft.model_parent_id}/${newCraft.model_id}`] = firstLevelRules[newCraft.model_id].rules);

      const nowRules = Object.values(nowRulesMap).reduce(({ disabled: preDis, ...preLeft }, { disabled: curDis, ...curLeft }) => {
        return { disabled: { ...preDis, ...curDis }, ...preLeft, ...curLeft };
      }, { disabled: {} });
  
      tempCraftworks = tempCraftworks.map((item, index) => {
        const newItem = item;
        newItem.disabled = nowRules.disabled[item.model_id];
        const enabledArr = nowRules[item.model_id] && nowRules[item.model_id].enabled;
        if (enabledArr && enabledArr.length > 0 && enabledArr.indexOf(item.children[activeIndex2[index]].model_id) === -1) {
          const findIndex = item.children.findIndex(item => {
            return enabledArr.findIndex(element => {
              if (element.key) {
                return element.value === item[element.key];
              } else {
                return element === item.model_id;
              }
            }) !== -1;
          });
          if (findIndex >=0 && findIndex < item.children.length) {
            item.children[array[index]].selected = false;
            array[index] = findIndex;
            item.children[array[index]].selected = true;
          }
        }
        newItem.children = item.children.map(element => {
          const newElement = element;
          newElement.disabled = false;
          enabledArr && (
            newElement.disabled = enabledArr.findIndex( emt => {
              if (emt.key) {
                return emt.value === element[emt.key];
              } else {
                return emt === element.model_id;
              }
            }) === -1
          );
          return newElement;
        })
        return newItem;
      });
    }
  }

  toNext = async () => {
    if (this.nexting) {
      return;
    }
    this.nexting = true;
    try {
      const { fabricsInfo, fabricIndex, firstCraftworks, activeIndex2, price } = this.state;
      const { sdk } = this;
      // await sdk.resetCamera();
      //加载场景
      if (urlParams.category_code ==='MDY') {
        await sdk.resetCamera(1, [1, 5]);
      } else if (urlParams.category_code ==='MCY'){
        await sdk.resetCamera(1, [1, 5]);
      } else {
        await sdk.resetCamera(1.8, [1, 5]);
      }
      const shot_url = await sdk.shot();

 
      // const result = await upLoad('/api/public/file/upload',formData);
      // console.log('上传图片==================', result,formData)
      const { category_id, category_name, product_id, category_code, token, appid, openid } = urlParams;
      let newImage_url = await getBlobWithUrl(shot_url);  // base64
      const imageFile = dataURLtoFile(newImage_url);
      let formData = new FormData();
      formData.append("file",imageFile);
      formData.append("file_prefix",'shot_url');
      const result = await upLoad('/api/public/file/upload',formData);
      console.log('上传图片==================', result)
      const image_url = result.data.file_url
      // if (urlParams.token) { // 上传文件，传递url
      //   console.log(urlParams.token);
      //   const imageFile = dataURLtoFile(image_url);
      //   const jsonStr = await upLoadFile(imageFile, token);
      //   image_url = JSON.parse(jsonStr).data.file_url;
      // }else{
      //   console.log("openid====", openid);
      //   const imageFile = dataURLtoFile(image_url);
      //   const jsonStr = await csUpLoadFile(imageFile, appid, openid);
      //   image_url = JSON.parse(jsonStr).data.file_url;
      // }
      

      // { default_flag, fabric_code, fabric_id, fabric_intro, fabric_name, fabric_price, fabric_vip_price, image_url, show_order }
      const custom_fabrics = fabricsInfo[fabricIndex];


      // { craft_code, craft_id, craft_image, craft_intro, craft_name, craft_price, default_flag, position_code, position_id, position_name, show_order }
      const custom_craftworks = []
      firstCraftworks.forEach(({ children, craftwork_id: position_id, craft_name: position_name, craft_code: position_code, model_flag, disabled }, index) => {
        if (model_flag == 1 && !disabled && children && children.length > activeIndex2[index]) {
          const { craft_name, craft_image, craft_code, model_id, craft_price, craftwork_id } = children[activeIndex2[index]];
          custom_craftworks.push({ craft_name, craft_code, craft_id: craftwork_id, position_id, position_name, position_code: position_code || '' });
        }
      });
      let vip_price = fabricsInfo[fabricIndex].fabric_vip_price;
      for(let i=0; i<firstCraftworks.length; i++){
        vip_price += firstCraftworks[i].children[activeIndex2[i]].craft_price;
      }
      vip_price = vip_price.toFixed(2)
      const params = [{ product_id, image_url, category_id, category_code, category_name, custom_fabrics, custom_craftworks, price, vip_price }];
      console.log('clickNextParams', params)
      if (urlParams.navUrl) {
        wx.miniProgram.navigateTo({url:`${urlParams.navUrl}?params=${encodeURIComponent(JSON.stringify(params))}`});
      } else {
        alert('仅供演示，敬请期待');
      }
    } catch (e) {
      console.error('=======toNext error========', e);
    }
    this.nexting = false;
  }

  stopInteral = () => {
    if (this.interal) {
      clearInterval(this.interal);
      this.interal = null;
    }
  }
}

export default App;

